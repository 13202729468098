@import '../colors.scss';
@import '../header.scss';
@import '../button.scss';

.ui.grid.box {
  margin: 50px 70px 20px 60px !important;
}
.box {
  width: 900px;
  height: 130px;
  border-radius: 10px;
  box-shadow: 0 10px 20px 5px $azure-10;
  background-color: $white;
  font-family: Futura;
  .content {
    width: 469px;
    height: 60px;

    margin-top: 35px;
    margin-left: 40px;
  }
  .box-button {
    @include b-primary(160px, 50px, $salmon, 14px, 1.86);
    float: right;
    margin-top: 40px !important;
    margin-left: 55px !important;
  }
}

i.big.icon.image {
  font-size: 2.8em;
  margin-left: 20px;
  margin-top: 20px;
  color: $azure;
}
i.big.icon.folder {
  font-size: 2.8em;
  margin-left: 20px;
  margin-top: 20px;
  color: $azure;
}
i.big.icon.database {
  font-size: 2em;
  margin-left: 24px;
  margin-top: 22px;
  color: $azure;
}
.mask {
  margin-left: 60px;
  margin-top: 25px;
  width: 80px;
  height: 80px;
  border-radius: 20px;
  background-image: url('../../images/mask.png');
}
.ui.grid > * {
  padding: 0;
}

.box-header {
  @include header1(24px, 500, 1.38, $marine-blue, 1px);
}
.box-data {
  margin-top: 6px;
  @include header1(13px, normal, 1.31, $battleship-grey, 1px);
}

.filepond--root > .filepond--panel {
  border-radius: 30px;
  background-color: $salmon;
}
.filepond--drop-label.filepond--drop-label label {
  font-family: Futura;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  margin-top: -16px;
  letter-spacing: normal;
  text-align: center;
  color: $white;
  cursor: pointer;
}
