@font-face {
  font-family: 'Futura';
  src: local('MyFont'), url(../fonts/futura_medium_bt.ttf) format('truetype');
}
@font-face {
  font-family: 'Cabin';
  src: local('MyFont'), url(../fonts/cabin-regular.ttf) format('truetype');
}
@font-face {
  font-family: 'FuturaBT';
  src: local('MyFont'), url(../fonts/futura_book.ttf) format('truetype');
}
@font-face {
  font-family: 'Futura-Book';
  src: local('MyFont'), url(../fonts/futura_book.ttf) format('truetype');
}
