@import '../colors.scss';
@import '../header.scss';

.analyse {
	float: right;
	width: 1030px;
	min-height: 630px;
	border-radius: 20px;
	background-image: url('../../images/bg.jpeg');
	font-family: Futura;
	padding-bottom: 20px;
	.back {
		float: left;
		margin: 60px 0px 40px 50px;
		.button {
			width: 170px;
			height: 40px;
			border-radius: 20px;
			background-color: $bluegrey !important;
			font-family: Cabin;
			font-size: 15px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.13;
			letter-spacing: normal;
			color: $pale-grey;
		}
	}
	.ui.container.box {
		margin-top: 140px;
		width: 950px;
		min-height: 890px;
		background-color: $white;
	}
	.ui.grid > .row {
		width: auto !important;
		margin-top: 52px;
	}
	.ui.grid > .column:not(.row),
	.ui.grid > .row > .column {
		padding: 0px 0px 30px 0px;
	}

	.ui.grid {
		margin: 0px 0px 0px 40px;
		.col1 {
			width: 200px !important;
			height: 330px;
			border-radius: 5px;
			background-color: $azure-10;
		}
		.col2 {
			margin-left: 20px;
			width: 200px !important;
			min-height: 700px;
			border-radius: 5px;
			background-color: $azure-10;
		}
		.icon {
			margin: auto;
			margin-top: 28px;
		}
		.area {
			margin: auto;
			margin-top: 24px;
		}
		.label {
			margin: auto;
			margin-top: 18px;
			width: 115px;
			height: 42px;
			@include header1(16px, normal, 1.25, $marine-blue, normal);
			text-align: center;
		}
		.defect-image {
			margin: auto;
			margin-top: 35px;
			width: 120px;
			height: 120px;
			border-radius: 8px;
		}
		.defect-image2 {
			margin-top: 91px;
		}
		.defect-image3 {
			margin: auto;
			margin-top: 50px;
			width: 120px;
			height: 120px;
			border-radius: 8px;
		}
		.defect-button {
			margin: 10px 0px 0px 20px;
			width: 160px;
			height: 30px;
			border-radius: 15px;
			background-color: $azure-two;
			@include header1(13px, normal, normal, $azure, normal);
			padding: 0px;
			.icon {
				margin-left: 10px !important;
			}
		}

		.defect-label {
			margin: auto;
			margin-top: 32px;
			width: 60px;
			height: 30px;
			@include header1(13px, normal, 1.08, $bluegrey, normal);
			text-align: center;
		}
		.list1 {
			margin: 28px 0px 0px 0px;
			@include header1(13px, normal, 1.08, $bluegrey, normal);
			text-align: center;
		}
		.list2 {
			margin-left: 40px;
			position: absolute;
			bottom: 80px;
		}
		.ui.list {
			.item {
				padding: 0px;
				width: auto;
			}
		}
		.header {
			// margin: 49px 0px 0px 110px;
			width: 582px !important;
			height: 79px;
			@include header1(18px, 500, 1.67, $marine-blue, normal);
			margin: 0px;
			text-align: left;
		}
	}
	.data {
		min-height: 240px;
	}
}
