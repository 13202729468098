@import '../colors.scss';
@import '../header.scss';

.subgroups {
	float: right;
	width: 1030px;
	border-radius: 20px;
	background-image: url('../../images/bg.jpeg');
	font-family: Futura;

	.ui.grid {
		margin: 60px 0px 0px 34px;
	}
	.ui.grid > .column:not(.row) {
		padding: 0px;
	}
	.chart1 {
		width: 483px !important;
		height: 378px;
		border-radius: 10px;
		box-shadow: 0 2px 1px 0 $azure-5;
		background-color: $white;
		.recharts-text {
			fill: $bluegrey;
			text-anchor: middle;
		}
	}
	.chart2 {
		width: 483px !important;
		height: 378px;
		margin-left: 16px;
		border-radius: 10px;
		box-shadow: 0 2px 1px 0 $azure-5;
		background-color: $white;
		.recharts-text {
			fill: $bluegrey;
			text-anchor: middle;
		}
	}
	.chart3 {
		width: 483px !important;
		height: 378px;
		margin-top: 16px;
		border-radius: 10px;
		box-shadow: 0 2px 1px 0 $azure-5;
		background-color: $white;
		.recharts-text {
			fill: $bluegrey;
			text-anchor: middle;
		}
	}
	.chart4 {
		width: 483px !important;
		height: 378px;
		border-radius: 10px;
		box-shadow: 0 2px 1px 0 $azure-5;
		background-color: $white;
		margin-top: 16px;
		margin-left: 16px;
	}
	.chart5 {
		width: 483px !important;
		height: 378px;
		border-radius: 10px;
		box-shadow: 0 2px 1px 0 $azure-5;
		background-color: $white;
		margin-top: 16px;
	}
	.chart6 {
		width: 483px !important;
		height: 378px;
		border-radius: 10px;
		box-shadow: 0 2px 1px 0 $azure-5;
		background-color: $white;
		margin-top: 16px;
		margin-left: 16px;
	}
	.chart7 {
		width: 483px !important;
		height: 378px;
		border-radius: 10px;
		box-shadow: 0 2px 1px 0 $azure-5;
		background-color: $white;
		margin-top: 16px;
		margin-bottom: 20px;
	}
	.chart8 {
		width: 483px !important;
		height: 190px;
		border-radius: 10px;
		box-shadow: 0 2px 1px 0 $azure-5;
		background-color: $white;
		margin-top: 16px;
		margin-left: 16px;
	}
	.chart-label {
		margin-top: 28px;
		margin-left: 24px;
		width: auto;
		height: 50px;
		@include header1(20px, 500, 1.3, $marine-blue, normal);
	}
	.recharts-text {
		@include header11(11px, normal, 1.45, normal);
		text-align: right;
		// fill: $bluegrey
	}
	.recharts-legend-item-text {
		@include header1(11px, normal, 1.27, $bluegrey, normal);
		fill: '$bluegrey';
	}
	.recharts-text .recharts-label {
		@include header1(11px, normal, 1.27, $bluegrey, normal);
		fill: '$bluegrey';
	}
	.chart4,
	.chart5,
	.chart6 {
		.row {
			.recharts-wrapper {
				.recharts-legend-wrapper {
					// left: 0px !important;
					// top: 0px !important;
					@include header1(11px, normal, 1.27, $bluegrey, normal);
					span {
						margin-left: -20px;
					}
					li {
						margin-bottom: 24px;
						font-size: 18px;
						font-weight: 500;
					}
				}
			}
		}
	}
	.sublabel {
		margin-left: 26px;
		@include header1(11px, normal, 1.45, $bluegrey, normal);
		text-align: center;
	}
	.row > .image {
		margin: 42px auto 0px auto;
		width: 117px;
		height: 30px;
	}
}
.ui.button.next {
	width: 117px;
	height: 30px;
	margin-top: 7%;
	margin-left: 36%;
	@include header1(13px, normal, 2px, $salmon, normal);
}
.ui.labeled.icon.button.next > .icon {
	background-color: unset;
	right: 5px;
}
.ui[class*='right labeled'].icon.button.next {
	padding-left: 2px !important;
	padding-right: 20px !important;
}
