@import '../colors.scss';
@import '../header.scss';

.Rectangle {
  height: 94px;
  box-shadow: 0 2px 20px 0 #0000000e;
  display: flex;
  justify-content: space-between;
}
.header {
  @include header2(20px, 500, 1.65, $marine-blue, normal);
  padding-top: 24px !important;
  float: left;
  display: flex;
  .diamond {
    width: 58px;
    height: 58px;
    background-image: url('../../images/diamond.png');
    background-repeat: no-repeat;
    margin-left: 44px;
  }
  .title {
    margin-left: -28px;
    margin-top: 8px;
  }
}
.logo {
  float: left;
  margin-top: 18px;
  margin-left: 40px;
  width: 149px;
  height: 54px;
}

.profile {
  float: right;
  margin-top: 25px;
  margin-right: 40px;
  width: 141px;
  height: 40px;
  object-fit: contain;
  float: right;
}
.start {
  display: flex;
  float: right;
  .ui.button {
    background-color: $white;
    height: 40px;
    margin-right: 10px;
    margin-top: 28px;
  }
  .ui.button:hover {
    background-color: $white;
    box-shadow: 0px;
  }
}
.ui.icon.button:hover {
  background-color: 'none';
}
