@import '../colors.scss';

.trend-charts {
  .trendc {
    margin-left: 49.8px;
    margin-top: 40px !important;
    width: 930px;
    height: 284px;
    background: $white;
    border-radius: 5px;
    box-shadow: 0 1px 15px 0 $black-6;
    background-color: $white;
    .trendc-row {
      font-size: 20px;
      width: 116px;
      height: 33px;
      font-weight: 500;
      margin-top: 30px;
      margin-left: 59px;
      color: $marine-blue;
    }
    .trendc-row2 {
      height: 40px;
      .button {
        width: 140px;
        height: 44px;
        border-radius: 22px;
        border: solid 1px $grapefruit-two;
        margin-left: 59px;
        margin-bottom: 45px;
        background: transparent;
        color: $grapefruit-two;
      }
      .button:hover {
        width: 140px;
        height: 44px;
        border-radius: 22px;
        background-color: $grapefruit-two;
        color: $white;
      }
      .button:focus {
        background-color: $pale-red;
        color: $white;
      }
    }
    .trendc-row3 {
      display: flex;
      .drop-btn {
        margin-left: 40px;
        margin-top: 20px;
        padding-bottom: 40px;
        text-align: left;
        font-family: Cabin;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        color: $marine-blue;
        width: 200px;
        height: 50px;
        border-radius: 3px;
        box-shadow: 0 2px 20px 0 $black-5;
        border: solid 1px $marine-blue-8;
        background-color: $white;
      }
      .ui[class*='right labeled'].icon.button > .icon {
        background: $white;
        color: $marine-blue;
      }
    }
  }
  .charts-div {
    display: flex;
    .chart {
      margin-left: 49.8px;
      margin-top: 24px !important;
      width: 460px;
      height: 330px;
      border-radius: 10px;
      box-shadow: 0 2px 30px 0 $black-6;
      background-color: $white;
    }
    .chart2 {
      margin-left: 24px;
      margin-top: 24px !important;
      width: 460px;
      height: 330px;
      border-radius: 10px;
      box-shadow: 0 2px 30px 0 $black-6;
      background-color: $white;
    }
  }
}
