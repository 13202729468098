@import '../header.scss';
@import '../colors.scss';
@import '../button.scss';

.DataAcquisition,
.DataAnnotation {
  .ui.table {
    padding: 0px;
    margin: 0px;
    border-collapse: separate;
    border-spacing: 0 15px;
    background-color: transparent;
    border: none;
    width: 900px;
    @include header1(15px, normal, 1.13, #80a1ae, normal);
    text-align: center;
  }
  .ui.table.db-table {
    text-align: left;
    .ui.image {
      margin: 0px;
    }
  }
  .ui.table tr th {
    padding: 0px;
    border: none;
    width: auto;
    background: none;
    @include header1(14px, 500, 1.86, #02435d, 1px);
  }
  .ui.table.db-table th {
    padding-left: 5px;
  }
  .unit-row {
    height: 86px;
    background-color: #ffffff;
    box-shadow: 0 2px 1px 0 rgba(28, 167, 229, 0.05);
    @include header1(14px, normal, 1.36, #707175, 1px);
  }
  .connect {
    float: left;
    width: 32px !important;
    margin: 0px;
    padding: 10px 0px 0px 10px !important;
  }
  .data {
    float: right;
    text-align: left;
    width: 150px !important;
    margin: 0px 0px 0px -20px;
    padding: 0px;
    .status {
      @include header1(24px, 500, 1.38, #02435d, normal);
    }
  }
  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .ui.grid {
    padding: 0px;
  }
}
.ui.button.results {
  @include b-third(110px, 30px, $salmon-20, 13px, 0.5);
}
