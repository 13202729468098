@import '../colors.scss';
@import '../button.scss';
@import '../header.scss';
@import '../label.scss';

.misclass {
	margin-left: 50.8px;
	margin-top: 22px !important;
	width: 90%;
	margin: auto;
	min-height: 575px;
	border-radius: 10px;
	box-shadow: 0 2px 30px 0 $black-6;
	background-color: $white;
	padding: 0 10px;
	.title {
		display: flex;
		flex-direction: row;
		.sub-title {
			margin-top: 10px;
			padding: 15px 0px 0px 0px;
			@include header2(18px, 500, 1.83, $marine-blue, normal);
		}
		div {
			width: 70%;
			.box-button {
				@include b-primary(140px, 44px, $salmon, 14px, 1);
				float: right;
				margin-top: 25px;
			}
		}
	}
	.misclass-analysis {
		padding-bottom: 20px;
		.ui.loader {
			margin-top: 40px;
			display: block;
		}
		.load.row {
			margin-top: 30px;
			.ui.button {
				margin: auto;
				@include b-secondary(140px, 44px, $white, 12px, normal);
				border: solid 2px $grapefruit-two;
				font-family: Futura;
			}
		}
		.select-filter {
			display: block;
			margin-left: 53px;
			.filter-title {
				width: 103px;
				height: 33px;
				font-family: FuturaBT;
				font-size: 16px;
				line-height: 33px;
				color: $marine-blue;
			}
		}
		.ui.dropdown {
			.menu {
				margin-left: 40px;
				width: 215px;
				height: 250px;
				overflow: hidden;
				.item {
					@include header1(12px, 500, 0.5, $marine-blue, normal);
				}
			}
		}
		.ui.multiple.dropdown {
			margin-left: 30px;
			margin-top: 20px;
		}
		.unit.ui.dropdown {
			.menu {
				overflow-y: scroll;
			}
		}
		.horizontal-line {
			width: 850px;
			height: 1px;
			opacity: 0.5;
			border: solid 1px #979797;
		}
		.drop-btn {
			text-align: left;
			font-family: Cabin;
			font-size: 14px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;
			color: $marine-blue;
			width: 215px;
			height: 50px;
			border-radius: 3px;
			box-shadow: 0 2px 20px 0 $black-5;
			border: solid 1px $marine-blue-8;
			background-color: $white;
		}
		.ui[class*='right labeled'].icon.button > .icon {
			background: $white;
			color: $marine-blue;
		}
		.line {
			width: 1px;
			height: 78px;
			opacity: 0.3;
			border: solid 1px #979797;
			margin-left: 40px;
		}
		.line2 {
			width: 1px;
			height: 78px;
			opacity: 0.3;
			border: solid 1px #979797;
			margin-left: -80px;
		}

		.selected-filters {
			width: 90% !important;
			margin: auto;
			.column-box2 {
				.select-filter-title {
					height: 36px;
					display: flex;
					.selected-title {
						// width: 223px;
						height: 33px;
						font-size: 14px !important;
						line-height: 33px;
						color: $marine-blue;
						display: flex;
						font-family: Cabin;
						.show-filter-btn {
							width: 148px;
							height: 18px;
							font-family: Cabin;
							font-size: 14px;
							font-weight: 500;
							color: $grapefruit-two;
							background: transparent;
						}
					}
				}
				.hr {
					height: 1px;
					opacity: 0.5;
					border: solid 1px #979797;
				}
				.selected-filter-results-list {
					display: flex;
					border-top: 1px solid $warm-grey-three;
					.selected-result {
						width: 214px;
						margin-left: 23px;
						margin-left: 14px;
						margin-top: 14px;
						.selected-filter-title {
							width: 214px;
							height: 33px;
							@include header1(16px, 500, 33px, $marine-blue, normal);
							margin-bottom: 5px;
							margin-top: 5px;
						}
						.selected-filter-results {
							min-width: 191px;
							height: auto;
							display: flex;
							flex-wrap: wrap;
							.label {
								display: flex;
								margin: 0 5px 5px 5px;
								padding: 0 15px;
								background: $azure-1;
								@include label(12px, 2.8em, $marine-blue, 35px);
								.close {
									cursor: pointer;
									float: right;
									padding-left: 10px;
								}
							}
						}
					}
				}
				.vr {
					width: 1px;
					height: 78px;
					opacity: 0.3;
					border: solid 1px #979797;
					margin-top: 24px;
				}
				.button {
					margin-top: 50px;
					margin-left: 10px;
					width: 40px;
					height: 40px;
					// opacity: 0.2;
					border-radius: 5px;
					// background: $dark-sky-blue-three;
					.icon {
						margin: -14.5px !important;
						color: $marine-blue;
					}
				}
				.selected {
					background-color: $dark-sky-blue-20;
				}
				.unselected {
					background-color: $white-four;
				}
				.button::focus {
					background-color: $dark-sky-blue-three;
				}
			}
		}

		.show-result {
			width: 190px;
			height: 65px;
			font-family: Futura;
			font-size: 18px;
			line-height: 33px;
			color: $marine-blue;
			margin-left: 59px;
			margin-top: 10px;
		}
		.ui.table {
			width: 850px;
			margin-left: 66px;
			height: auto;
			border-collapse: separate;
			border-spacing: 0 5px;
			border: none;
		}
		.ui.table thead th {
			box-shadow: none;
			font-family: Cabin;
			font-size: 16px;
			font-weight: 500;
			color: $marine-blue;
			background: transparent;
			border: none;
		}
		th {
			border: none;
		}
		td {
			border: solid 0.5px rgba(112, 112, 112, 0.36) !important;
		}
		td > img {
			width: 54px;
			height: 54px;
			border-radius: 10px;
			margin: auto;
		}
		.misclass-row {
			height: 70px;
			font-family: Cabin;
			font-size: 12px;
			font-weight: 500;
			line-height: 18px;
			color: $marine-blue;
		}

		.pagination {
			.show.column {
				margin-left: 30px;
				width: 125px;
				.row {
					.label {
						background-color: $white;
						@include header1(15px, normal, 1.13, $marine-blue, normal);
					}
					input {
						width: 40px;
						height: 40px;
						object-fit: contain;
						border-radius: 4px;
						box-shadow: 0 2px 1px 0 $azure-5;
						border: solid 0.5px $warm-grey-two;
						@include header1(15px, normal, 1.13, $marine-blue, normal);
						font-family: Cabin;
					}
				}
			}
			.goto.column {
				width: 200px;
				.row {
					.label {
						background-color: $white;
						@include header1(15px, normal, 1.13, $marine-blue, normal);
					}
					input {
						width: 40px;
						height: 40px;
						object-fit: contain;
						border-radius: 4px;
						box-shadow: 0 2px 1px 0 $azure-5;
						border: solid 0.5px $warm-grey-two;
						@include header1(15xp, normal, 1.13, $marine-blue, normal);
					}
				}
			}
			.navigation.column {
				margin-left: 380px;
				.buttons {
					.left {
						margin-right: 10px;
					}
					.left,
					.right {
						border-radius: 5px;
						background-color: $dark-sky-blue-30;
						i {
							background-color: rgba(51, 182, 220, 0);
						}
					}
				}
			}
		}
	}
	.displayDrop {
		width: 283px !important;
		height: 245px !important;
		box-shadow: 0 2px 20px 0 $black-11;
		background-color: $white;
		.displayMenu {
			width: 252px;
			height: 209px;
			margin: 11px 11px 25px 20px;
			.dropTitle {
				width: 252px;
				height: 30px;
				margin: 10px 0 0 0;
				@include header1(16px, 600, 30px, $marine-blue, normal);
			}
			.dropRadioBtn {
				width: 252px;
				height: 96px;
				margin: 13px 0 0 0;
				font-family: Cabin;

				.form {
					.field {
						.ui.checkbox {
							label {
								@include header1(14px, normal, normal, $marine-blue !important, normal);
							}
						}
					}
				}
			}
			.applyBtn {
				margin: 20px 0 0 0;
				@include b-secondary(114px, 40px, transparent, 12px, normal);
			}
			.applyBtn:hover {
				width: 114px;
				height: 40px;
				border-radius: 22px;
				background-color: $grapefruit-two;
				color: $white;
			}
			.applyBtn:focus {
				background-color: $pale-red;
				color: $white;
			}
		}
	}
	.ui.checkbox .box:before,
	.ui.checkbox label:before {
		border: 2px solid $azure;
	}
	.ui.checkbox input:checked ~ .box:before,
	.ui.checkbox input:checked ~ label:before {
		border: 2px solid $azure;
	}
	.ui.radio.checkbox label:after {
		background-color: $azure !important;
		// border: 2px solid $azure;
	}
	.card-grid {
		display: flex;
		flex-wrap: wrap;
		margin-top: 15px;
		justify-content: flex-start;
		margin-left: 4%;
		.card {
			margin: 1em 1%;
			width: 275px;
			height: 179px;
			padding-left: 10px;
			border-radius: 9px;
			box-shadow: 2px 5px 15px 0 #000f5611;
			background-color: $white;
			.card-row {
				padding-top: 15px;
				.index {
					@include header1(10.8px, 500, 1.5, $marine-blue, normal);
				}
				.card-row-column {
					.ui.image {
						width: 54px;
						height: 54px;
						border-radius: 10px;
					}
				}
				.card-row-column2 {
					word-break: break-all;
					height: 48px;
					@include header1(12px, 500, 2, $marine-blue, normal);
				}
			}
			.card-row2 {
				padding-bottom: 0;
				margin-left: 14px;
				@include header1(12px, 500, 1.6, $marine-blue, normal);
				.column {
					margin-right: 5px;
				}
				.title {
					width: 61px;
					margin: 0;
					padding: 0;
					@include header1(12px, normal, 2.4, $marine-blue, normal);
				}
				.code {
					display: flex;
					flex-direction: column;
					justify-content: center;
					width: 65px;
					height: 25px;
					padding: 0;
					span {
						@include header1(12px, 500, normal, $dark-sky-blue-four, normal);
					}
				}
				.right {
					width: 47px;
					height: 29px;
					.button {
						width: 47px;
						height: 29px;
						border-radius: 15px;
						font-family: Cabin;
						font-size: 8.4px;
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: normal;
						letter-spacing: normal;
						.icon {
							left: 25%;
							background: none;
						}
					}
				}
				.wrong {
					width: 47px;
					height: 29px;
					.button {
						width: 47px;
						height: 29px;
						border-radius: 15px;
						font-family: Cabin;
						font-size: 8.4px;
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: normal;
						letter-spacing: normal;
						.icon {
							left: 28%;
							background: none;
						}
					}
				}
			}
			.card-row3 {
				padding-top: 0;
				height: 30px;
				font-family: Cabin;
				font-size: 12px;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				color: $marine-blue;
				margin: 15px 0 0 14px;
				.feedback {
					width: 51px;
					height: 15px;
					font-family: Cabin;
					font-size: 12px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: 2;
					letter-spacing: normal;
					color: $marine-blue;
				}
				.drpdwn {
					margin-left: 10px;
					width: 100px;
					height: 28px;

					span {
						font-family: Cabin;
						font-size: 12px;
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: normal;
						letter-spacing: normal;
						color: $dark-sky-blue-four;
						line-height: 2;
					}
					.ui.dropdown {
						width: 100px;
						height: 28px;
						border-radius: 3px;
						box-shadow: 0 2px 20px 0 $black-5;
						border: solid 1px $marine-blue-8;
						background-color: $white;
						line-height: 2.4;
						padding-left: 15px;
						.text {
							font-family: Cabin;
							font-size: 10px;
							font-weight: normal;
							font-stretch: normal;
							font-style: normal;
							letter-spacing: normal;
							color: $marine-blue;
						}
						.menu {
							margin-left: 0;
							width: 100px;
							overflow-y: scroll;
						}
					}
				}
			}
		}
	}
}
.trend {
	margin-left: 49.8px !important;
	margin-top: 30px !important;
	width: 465px;
	height: 182px;
	border-radius: 10px;
	box-shadow: 0 2px 30px 0 $black-6;
	background-color: $white;
	.trend-row {
		.trend-row-column {
			width: 232px !important;
			font-family: Futura;
			font-size: 18px;
			font-weight: 500;
			line-height: 33px;
			color: $marine-blue;
			margin-top: 20px !important;
			margin-left: 40px;
		}
		.trend-row-column2 {
			width: 232px;
		}
	}
	.trend-row2 {
		.trend-row2-column {
			width: 232px !important;
			.button {
				width: 140px;
				height: 44px;
				border-radius: 22px;
				border: solid 1px $grapefruit-two;
				margin-left: 40px;
				margin-bottom: 45px;
				background: transparent;
				color: $grapefruit-two;
			}
			.button:hover {
				width: 140px;
				height: 44px;
				border-radius: 22px;
				background-color: $grapefruit-two;
				color: $white;
			}
			.button:focus {
				background-color: $pale-red;
				color: $white;
			}
		}
		.trend-row2-column2 {
			width: 232px;
		}
	}
}
.aiResult-grid {
	width: 360px;
	height: 555px;
	box-shadow: 0 2px 20px 0 $black-11;
	background-color: $white;
	.aiResultColumn {
		width: 161px;
		height: 445px;
		display: flex;
		border-right: 2px solid rgba(34, 36, 38, 0.15);
		margin-top: 25px;
		.aiResultLabel {
			width: 161px;
			height: 30px;
			margin-left: 30px;
			display: flex;

			.aiResultTitle {
				font-family: Cabin;
				font-size: 14px;
				font-weight: 600;
				line-height: 30px;
				color: $marine-blue;
			}
			.button {
				font-family: Cabin;
				font-size: 12px;
				font-weight: 500;
				color: $grapefruit-two;
				margin-left: 16px;
				background: transparent;
			}
		}
		.searchInput {
			.ui.icon.input > input {
				color: $salmon;
				width: 150px;
			}
		}
		.ui.checkbox {
			margin: 5px 0px 10px 33px;

			input[type='checkbox'] {
				&:checked + label:after {
					background-image: linear-gradient(179deg, $azure 33%, $seafoam-blue 235%);
					border-radius: 2px;
					border-style: solid;
					border-width: 0.5px;
					border-color: $azure;
					color: white; /* Color of the checkmark */
					content: '\2713'; /* This is a "heavy" checkmark. Change to 2713 for a light one. */
					font-size: 14px;
				}
			}
		}
		.check-list {
			display: flex;
			flex-direction: column;
			height: 350px;
			width: 161px;
			overflow-x: auto;
			margin-top: 15px;
		}

		.ui.checkbox input {
			width: 15px;
			height: 15px;
		}
		.ui.checkbox label {
			font-family: Cabin;
			font-size: 14px;
			line-height: 17px;
			color: $marine-blue;
		}
	}
	.aiResultColumn2 {
		width: 191px;
		height: 420px;
		display: flex;
		margin-top: 25px;
		.aiResultLabel {
			width: 191px;
			height: 30px;
			margin-left: 30px;
			display: flex;
			.aiResultTitle {
				font-family: Cabin;
				font-size: 14px;
				font-weight: 600;
				line-height: 30px;
				color: $marine-blue;
			}

			.button {
				font-family: Cabin;
				font-size: 12px;
				font-weight: 500;
				color: $grapefruit-two;
				margin-left: 16px;
				background: transparent;
			}
		}
		.searchInput {
			.ui.icon.input > input {
				color: $salmon;
				width: 150px;
			}
		}
		.ui.checkbox {
			margin: 5px 0px 10px 33px;
		}
		.check-list {
			display: flex;
			flex-direction: column;
			height: 350px;
			width: 162px;
			overflow-x: auto;
			margin-top: 15px;
		}
		.ui.checkbox input {
			width: 15px;
			height: 15px;
		}
		.ui.checkbox label {
			font-family: Cabin;
			font-size: 14px;
			line-height: 17px;
			color: $marine-blue;
		}
	}
	.aiResultButton {
		width: 114px;
		height: 40px;
		.button {
			color: #f56c6c;
			margin: 0 0 24px 30px;
			@include b-secondary(114px, 40px, $white, 12px, normal);
		}
	}
}
.ui.green.button,
.ui.green.buttons .button {
	background-color: $shamrock-80;
}
.extraClass1 {
	margin-top: 0px !important;
	text-align: center;
	@include header1(12px, 500, normal, $marine-blue !important, normal);
}
.ui.modal.defect-modal,
.ui.modal.p-modal {
	height: 100% !important;
	width: 100% !important;
	left: auto !important;
	background-color: $pale-grey !important;
	text-align: center !important;
}

.p-modal .scrolling.content {
	margin: auto;
	max-height: 100% !important;
	// width: 70% !important;
	height: 100% !important;
	.data {
		flex-direction: column !important;
		.image-all {
			width: 155px;
			.ui.image {
				margin: auto;
				width: 60px;
				height: 60px;
				border-radius: 10px;
			}
		}
		.ui.grid {
			margin-top: 20px;
			.ui.image {
				margin-bottom: 20px;
			}
		}
		.zoom-image {
			display: flex;
			flex-direction: row;
			background-color: $white;
			border-radius: 40px;
			.main-image {
				width: 50%;
				.TransformComponent-module_container__3NwNd {
					width: 100%;
					height: 100%;
					border-radius: 40px;
					.TransformComponent-module_content__TZU5O {
						width: 100%;
						height: 100%;
						img {
							width: 100%;
							height: 100%;
						}
					}
				}
			}

			.image-content {
				padding: 20px;
				padding-left: 50px;
				width: 50%;
				height: 100%;
				border-top-right-radius: 40px;
				border-bottom-right-radius: 40px;
				background-color: $white;
				.close-button {
					height: 24px;
					margin-top: 42px;
					margin-right: 40px;
				}
				.image-number {
					height: 35px;
					margin-top: 27px;
					text-align: left;
					font-family: Futura;
					font-size: 20px;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.75;
					letter-spacing: normal;
					color: $marine-blue;
				}
				.image-name {
					height: 66px;
					margin-top: 9px;
					display: flex;
					flex-direction: row;
					.name {
						font-family: Futura;
						font-size: 20px;
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.65;
						letter-spacing: normal;
						color: $marine-blue;
						word-break: break-all;
						text-align: left;
					}
					.icon-book {
						width: 30%;
						margin-left: 107px;
					}
				}
				.image-result {
					height: 35px;
					margin-top: 20px;
					margin-bottom: 15px;
					display: flex;
					flex-direction: row;
					.ai-title {
						width: 69px;
						font-family: Futura;
						font-size: 16px;
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2.19;
						letter-spacing: normal;
						color: $marine-blue;
					}
					.fibre {
						display: flex;
						flex-direction: column;
						text-align: left;
						margin-left: 30px;
						margin-right: -55px;
						font-family: Futura;
						font-size: 16px;
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						letter-spacing: normal;
						color: $dark-sky-blue-four;
					}
					.right {
						width: 47px;
						height: 29px;
						.button {
							width: 56px;
							height: 36px;
							border-radius: 27px;
							font-family: Cabin;
							font-size: 11.4px;
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: normal;
							letter-spacing: normal;
							margin-left: 112px;
							.icon {
								left: 25%;
								background: none;
							}
						}
					}
					.wrong {
						width: 47px;
						height: 29px;
						.button {
							width: 56px;
							height: 36px;
							border-radius: 27px;
							font-family: Cabin;
							font-size: 11.4px;
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: normal;
							letter-spacing: normal;
							margin-left: 137px;
							.icon {
								left: 28%;
								background: none;
							}
						}
					}
				}
				.image-feedback {
					height: 35px;
					margin-top: 21px;
					display: flex;
					flex-direction: row;

					.ui.dropdown {
						height: 30px;
						width: 79px;
						display: flex;
						padding: 0;
						justify-content: space-evenly;
						align-items: center;
						border-radius: 3px;
						box-shadow: 0 2px 20px 0 $black-5;
						border: solid 1px $marine-blue-8;
						background-color: $white;
						line-height: 2.4;
						margin: 0;
						margin-left: 25px;
						margin-top: 5px;
						.text {
							width: 53px;
							margin: 0;
							font-family: Cabin;
							font-size: 10px;
							font-weight: normal;
							font-stretch: normal;
							font-style: normal;
							letter-spacing: normal;
							color: $marine-blue;
						}
						.icon {
							margin: 0;
						}
						.menu {
							margin-left: 0;
							width: 147px;
							height: 306px;
							// overflow-y: scroll;
							.heading {
								display: flex;
								font-family: Cabin;
								font-size: 10px;
								font-weight: normal;
								font-stretch: normal;
								font-style: normal;
								line-height: 3;
								letter-spacing: normal;
								color: $marine-blue;
								div:first-child {
									margin-left: 10px;
								}
								.icon-grp {
									width: 60px;
									display: flex;
									justify-content: flex-end;
									img {
										margin-left: 10px;
										position: inherit;
										width: 10px;
									}
								}
							}
							.search {
								width: 113px;
								margin: auto;
							}
							.search > input {
								width: 113px;
								height: 25px;
								border: solid 0.7px $marine-blue-43;
							}
							.options {
								height: 193px;
								display: flex;
								flex-direction: column;
								overflow-y: scroll;
								overflow-x: hidden;
								margin: 10px 0 5px 0;
								.ui.radio.checkbox {
									min-height: 20px;
									display: flex;
									align-items: center;
									width: 130px;
									margin: auto;
									[type='radio']:checked,
									[type='radio']:not(:checked) {
										position: absolute;
										left: -9999px;
									}
									[type='radio']:checked + label,
									[type='radio']:not(:checked) + label {
										position: relative;
										padding-left: 16px;
										font-family: Cabin;
										font-size: 10px;
										font-weight: normal;
										font-stretch: normal;
										font-style: normal;
										letter-spacing: normal;
										color: $marine-blue;
										cursor: pointer;
									}
									[type='radio']:checked + label:before,
									[type='radio']:not(:checked) + label:before {
										content: '';
										position: absolute;
										left: 0;
										top: 2.5px;
										width: 11px;
										height: 11px;
										border: 1px solid $azure;
										border-radius: 100%;
										background: #fff;
									}
									[type='radio']:checked + label:after,
									[type='radio']:not(:checked) + label:after {
										content: '';
										width: 6.25px;
										height: 6.25px;
										background-image: linear-gradient(179deg, $azure 33%, $seafoam-blue 235%);
										position: absolute;
										top: 4.6px;
										left: 2.8px;
										border: 1px solid $azure;
										border-radius: 100%;
										// -webkit-transition: all 0.2s ease;
										transition: none;
									}
									[type='radio']:not(:checked) + label:after {
										opacity: 0;
										-webkit-transform: scale(0);
										transform: scale(0);
									}
									[type='radio']:checked + label:after {
										opacity: 1;
										-webkit-transform: scale(1);
										transform: scale(1);
									}
								}
							}

							button {
								margin-left: 15px;
								@include b-secondary(63px, 22px, $white, 12px, normal);
							}
							::-webkit-scrollbar {
								width: 6px;
							}

							/* Track */
							::-webkit-scrollbar-track {
								box-shadow: inset 0 0 10px $white;
								border-radius: 10px;
							}

							/* Handle */
							::-webkit-scrollbar-thumb {
								background: $cloudy-blue;
								border-radius: 10px;
							}

							/* Handle on hover */
							::-webkit-scrollbar-thumb:hover {
								background: $white;
							}
						}
					}
					.feed-title {
						width: 69px;
						// margin-left: 50px;
						font-family: Futura;
						font-size: 16px;
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2.19;
						letter-spacing: normal;
						color: $marine-blue;
					}
					.fibre {
						text-align: left;
						min-width: 150px;
						margin-left: 30px;
						font-family: Futura;
						font-size: 16px;
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						letter-spacing: normal;
						line-height: 2.5;
						color: $dark-sky-blue-four;
					}
				}
				.pre-next {
					height: 44px;
					margin-top: 70px;
					display: flex;
					flex-direction: row;
					margin-left: 50px;
					margin-bottom: 50px;
					.button {
						@include b-primary(93px, 44px, $grapefruit-two, 16px, normal);
						border-radius: 22px;
						font-family: Futura;
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						letter-spacing: normal;
						padding-left: 30px !important;
						.icon {
							background: transparent;
						}
					}
					.button.disabled {
						@include b-primary-disabled();
					}
				}
				.actions {
					display: flex;
					flex-wrap: wrap;
					border-top: solid 1px $warm-grey-three;
					padding-top: 20px;
					width: 365px;
					.action,
					.action-last {
						cursor: pointer;
						margin-bottom: 15px;
						padding: 0 10px 0 0;
						margin-right: 10px;
						justify-content: center;
						border-right: solid 1px $warm-grey-three;
						font-family: FuturaBT;
						font-size: 12px;
						font-weight: normal;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.67;
						letter-spacing: normal;
						color: $marine-blue;
						img {
							float: left;
							margin-right: 5px;
						}
						.analyze {
							margin-top: 3px;
						}
					}
					.action-last {
						border: 0;
					}
				}
			}
		}
		.tools {
			margin-top: 15px;
			.scale {
				display: flex;
				// color: $white;
				width: fit-content;
				margin-left: 125px;
				padding: 10px;
				border-radius: 6px;
				// background: rgba(0, 0, 0, 0.7);
				i.small.icon,
				i.small.icons {
					margin: auto 15px;
				}
				div {
					margin-top: -8px;
				}
			}
			.prediction-tip {
				margin-top: -35px;
			}
		}
		.defect-name,
		.defect-class {
			@include header1(12px, normal, 1.58, $charcoal-grey, normal);
			text-align: center;
		}
	}
	.navigate-right {
		width: 29px;
		height: 19px;
		margin: auto;
		left: 40px;
		cursor: pointer;
	}
	.navigate-left {
		width: 29px;
		height: 19px;
		margin: auto;
		right: 40px;
		cursor: pointer;
	}
	.close {
		width: 26px;
		height: 26px;
		margin-left: 101%;
		margin-bottom: 15px;
		cursor: pointer;
	}
}
.ui.modal > .content {
	background: inherit !important;
}
#range1 {
	-webkit-appearance: none;
	outline: none;
	background: $black;
	height: 2px;
	width: 150px;
	border-radius: 5px;
	cursor: pointer;
	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: linear-gradient(169deg, $azure, $seafoam-blue);
	}
}

/* FIREFOX */
#range1::-moz-range-thumb {
	border: none;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	background: $black;
	cursor: pointer;
}
