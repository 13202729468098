.ui.modal.modal-viaiframe {
  height: 95% !important;
}
.ui.modal .scrolling.content.modal-viaimagezoom {
  padding: 0 0 20px 0;
}
.ui.modal .scrolling.content.modal-viaiframe-content {
  width: 100% !important;
  padding: 0 0 20px 0;
  border-radius: 3px;
  border: 5px solid #80a1ae;
}
.modal-viaimagezoom {
  .data {
    padding-top: 25px;
  }
  .tool-header {
    position: relative;
    margin-bottom: 20px;
    img {
      position: absolute;
      right: 0;
      bottom: 1px;
    }
  }
  .ui.button {
    width: 160px;
    height: 35px;
    background-color: #80a1ae !important;
    font-family: Cabin;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    padding: 0px;
    .icon {
      margin-left: 5px !important;
    }
  }
  .ui.button.via-btn {
    border-radius: 20px;
  }
  .ui.table {
    border-collapse: separate;
    border-spacing: 0px 10px;
    background-color: transparent !important;
    border: none !important;
    font-family: Cabin;
    font-size: 15px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center !important;
    color: #80a1ae !important;
  }

  .ui.table tr th {
    border: none;
    width: auto;
    background: none;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.86;
    letter-spacing: 1px;
    color: #02435d;
  }

  .unit-row {
    background-color: #ffffff;
    box-shadow: 0 1px 1px 0 #80a1ae;
    height: 70px;
  }

  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
