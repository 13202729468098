@import '../colors.scss';
@import '../header.scss';
@import '../button.scss';

.unitwise {
  .ui.multiple.dropdown {
    padding: 0;
  }
  .filter {
    display: flex;
    flex-direction: column;
    .ui.input {
      width: 243px;
      height: 35px;
      border-radius: 10px;
      margin: auto;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    .ui.checkbox {
      margin: 5px 0px 10px 20px;
    }
    .check-list {
      display: flex;
      flex-direction: column;
      height: 100px;
      overflow-x: auto;
      overflow-y: scroll;
    }
    .check-list-buttons {
      display: flex;
      flex-direction: row;
      margin: auto;
      padding-bottom: 10px;
      .ui.basic.button,
      .ui.basic.buttons .button {
        @include b-primary(70px, 30px, $salmon !important, 10px, 1.86);
        margin-right: 10px;
        color: $white !important;
      }
    }
  }
  .ui.checkbox .box,
  .ui.checkbox label {
    @include header1(12px !important, normal, 1.67, $bluegrey, normal);
  }
  .ui.multiple.dropdown .menu {
    cursor: pointer;
    width: 275px;
    min-height: 235px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 $steel-grey-20;
    background-color: $white;
    overflow: hidden;
  }
  .ui.dropdown.dropdown .menu > .input {
    bottom: 26px;
    margin: 0px 0px 0px 16px;
    width: 243px;
    height: 35px;
    border-radius: 10px;
  }

  .ui.icon.input > input {
    padding: 0px 5px;
    border-radius: 10px;
  }
  .sort-label {
    color: $bluegrey;
  }
  .filter-label {
    margin-left: 20px;
    bottom: 78px;
    width: 42px;
    height: 18px;
    @include header1(14px, normal, normal, $bluegrey, normal);
  }
  .sort-options {
    margin-top: 20px;
    margin-left: 20px;
  }
  .ui.checkbox input.hidden + label {
    width: 47px;
    height: 20px;
    @include header1(14px, normal, 1.4, $bluegrey, normal);
  }
  .ui.form .field {
    margin: 4px 0px 0px 0px;
  }
  .ui.radio.checkbox input:checked ~ .box:after,
  .ui.radio.checkbox input:checked ~ label:after {
    background-color: $salmon;
  }
  .close-icon {
    float: right;
    margin-right: 20px;
  }
  .ui.dropdown .menu > .divider {
    width: 245px;
    margin-left: 14px;
    margin-top: 16px;
    height: 2px;
  }
  .ui.dropdown .menu > .input:not(.transparent) input {
    @include header1(12px, normal, 1.67, $bluegrey, normal);
  }
  .predict {
    .ui.floating.dropdown .menu {
      width: 401px;
      min-height: 330px;
      left: -183px;
      right: auto;
      border-radius: 10px !important;
      box-shadow: 0 10px 20px 5px $azure-10 !important;
      .dd {
        display: flex;
        flex-direction: row;
        .ddImage {
          width: 107px;
          padding-top: 8px;
        }
        .ddData {
          width: 294px;
          padding-left: 16px;
          padding-top: 17px;
          display: flex;
          flex-direction: column;
          .dataTitle {
            display: flex;
            height: 33px;
            @include header2(16px, 500, 2.06, $marine-blue, normal);
          }
          .dataTitleTwo {
            height: 21px;
            @include header3(20px, normal, 1, $marine-blue, normal);
          }
          .dataInput {
            height: 71px;
            padding-top: 20px;
            .predictDefect > input {
              width: 243px;
              height: 32px;
              border-radius: 15px;
              padding: 0 10px;
            }
          }
          .dataRadio {
            overflow: auto;
            height: 211px;
            @include header1(12px, normal, 1.67, $bluegrey, normal);
          }
          .close-predict {
            margin-left: 38px;
          }
        }
      }
    }
  }
}
.ui.radio.checkbox .box:after,
.ui.radio.checkbox label:after {
  background-color: $salmon !important;
}
.ui.icon.input > i.icon {
  color: $salmon;
}

.unitwise .ui.checkbox input.hidden + label {
  color: inherit;
}

.dp-drpdwn {
  .menu {
    width: 352px;
    height: 403px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.11);
    .label {
      margin: 20px 0px 10px 26px;
      font-family: Cabin;
      font-size: 14px;
      line-height: 30px;
      color: $marine-blue;
      .close-icon {
        margin-right: 24px;
        float: right;
      }
    }
  }
}

.platforms {
  .ui.checkbox .box,
  .ui.checkbox label {
    font-family: Cabin;
    font-size: 14px;
    color: $marine-blue;
  }
  .close-icon {
    float: right;
    margin-right: 10.5px;
  }
  .menu {
    width: 252px;
    padding-bottom: 20px !important;
    box-shadow: 0 2px 20px 0 $black-11;
    background-color: $white;
  }
  .sort-label {
    color: $bluegrey;
  }
  .sort-label {
    margin-bottom: 9px;
    font-family: Cabin;
    font-size: 14px;
    line-height: 30px;
    color: $marine-blue;
  }
  .sort-options {
    margin-top: 20px;
    margin-left: 20px;
  }
  .p-check {
    margin-top: 15px;
    margin-left: 20px;
    .check-label {
      font-family: Cabin;
      font-size: 14px;
      line-height: 30px;
      color: $marine-blue;
    }
    .reset {
      margin-right: 15px;
      float: right;
      font-family: Cabin;
      font-size: 14px;
      font-weight: 500;
      line-height: 30px;
      text-align: center;
      color: $grapefruit-two;
    }
    .ui.form {
      font-family: Cabin;
      font-size: 14px;
      line-height: 35px;
      color: $marine-blue;
      .field {
        .ui.search {
          margin-top: 10px;
        }
        .show-all {
          font-family: Cabin;
          font-size: 14px;
          font-weight: 500;
          line-height: 30px;
          text-align: center;
          color: $grapefruit-two;
        }
      }
    }
    .button {
      width: 114px;
      height: 40px;
      border-radius: 22px;
      border: solid 1px $grapefruit;
      background: transparent;
      font-family: Cabin;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $grapefruit;
    }
  }
  .s-check {
    margin-top: 15px;
    margin-left: 20px;
    .check-label {
      font-family: Cabin;
      font-size: 14px;
      line-height: 30px;
      color: $marine-blue;
    }
    .reset {
      margin-right: 15px;
      float: right;
      font-family: Cabin;
      font-size: 14px;
      font-weight: 500;
      line-height: 30px;
      text-align: center;
      color: $grapefruit-two;
    }
    .ui.form {
      font-family: Cabin;
      font-size: 14px;
      color: $marine-blue;
      margin-bottom: 20px;

      .field {
        margin: 7px 0px;
      }
      .sub {
        margin-left: 25px;
        .checkbox {
          font-family: Cabin;
          font-size: 12px !important;
          line-height: 22px;
          color: $marine-blue;
        }
      }
    }
  }
  .s-check > .ui.button.applyBtn {
    margin: 20px 0 0 0;
    @include b-secondary(114px, 40px, transparent, 12px, normal);
  }
}
