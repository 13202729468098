@import '../header.scss';

.box-button {
	position: relative;
	transition: opacity 500ms;

	.spinner {
		width: 45px;
		text-align: center;
	}

	.d-inline-block {
		display: inline-block !important;
	}

	.spinner > span {
		width: 12px;
		height: 12px;
		margin: 0 1.5px;
		background-color: #fff;
		border-radius: 100%;
		display: inline-block;
		-webkit-animation: sk-bouncedelay 1.2s infinite ease-in-out both;
		animation: sk-bouncedelay 1.2s infinite ease-in-out both;
	}

	.spinner .bounce1 {
		-webkit-animation-delay: -0.32s;
		animation-delay: -0.32s;
	}

	.spinner .bounce2 {
		-webkit-animation-delay: -0.16s;
		animation-delay: -0.16s;
	}

	.spinner,
	.icon-span {
		position: absolute;
		left: 5px;
		right: 0;
		margin: 0 auto;
		opacity: 0;
		visibility: hidden;
		transform: translateY(-50%);
		transition: opacity 500ms;
		color: #fff;
	}
	.spinner {
		top: 50%;
	}
	.icon-span {
		top: 60%;
	}

	.icon-span i {
		vertical-align: text-bottom;
		font-size: 22px;
	}

	.label {
		transition: opacity 500ms;
	}

	&.show-spinner {
		.label {
			opacity: 0;
		}
		.spinner {
			opacity: 1;
			visibility: visible;
		}
	}

	&.show-success {
		.label {
			opacity: 0;
		}
		.icon-span.success {
			opacity: 1;
			visibility: visible;
		}
	}

	&.show-fail {
		.label {
			opacity: 0;
		}
		.icon-span.fail {
			opacity: 1;
			visibility: visible;
		}
	}
}

.ui.disabled.button.box-button:disabled {
	opacity: 1 !important;
}

.ui.popup {
	padding: 5px 10px;
	.content {
		font-family: FuturaBT;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: normal;
		color: $marine-blue !important;
	}
}

@-webkit-keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
