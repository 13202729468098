.paretoChart_ {
  display: flex;
}

.chart_ {
  flex: 4;
}

.legend_ {
  padding: 10px;
}

.legend {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 10px;
}

.legendLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4f4f4f;
  font-size: 0.95em;
  font-weight: 400;
  margin-bottom: 6px;
  cursor: default;
}

.labelSquare {
  flex: 1;
  width: 42px;
  height: 14px;
  margin: 0px 4px 0px 4px;
}

.labelLineThrough {
  text-decoration: line-through;
  font-size: 0.95em;
  font-weight: 400;
  color: #4f4f4f;
}

.label_ {
  font-size: 0.95em;
  font-weight: 400;
  color: #4f4f4f;
}

// .blue_{
//     background: rgba(0, 192, 239, 0.1);
// }

// .black_{
//     background: rgba(17, 17, 17, 0.1);
// }

// .blue_two_ {
//     background: rgba(11, 120, 206, 0.1);
// }

// .gray_ {
//     background: rgba(104,115,140, 0.1);
// }

// .green_ {
//     background:rgba(48, 187, 187, 0.1);
// }

// .pink_ {
//     background: rgba(216, 27, 96, 0.1);
// }

// .purple_ {
//     background: rgba(85, 82, 153, 0.1);
// }

// .orange_ {
//     background:rgba(255, 119, 1, 0.1);
// }

// .blue_dark{
//     background: rgba(0, 192, 239, 0.4);
// }

// .black_dark{
//     background: rgba(17, 17, 17, 0.4);
// }

// .blue_two_dark {
//     background: rgba(11, 120, 206, 0.4);
// }

// .gray_dark {
//     background: rgba(104,115,140, 0.4);
// }

// .green_dark {
//     background:rgba(48, 187, 187, 0.4);
// }

// .pink_dark {
//     background: rgba(216, 27, 96, 0.4);
// }

// .purple_dark {
//     background: rgba(85, 82, 153, 0.4);
// }

// .orange_dark {
//     background:rgba(255, 119, 1, 0.4);
// }

// .blueBorder{
//     border-color: rgba(0, 192, 239) !important;
// }

// .blackBorder{
//     border-color: rgba(17, 17, 17) !important;
// }

// .blue_twoBorder {
//     border-color: rgba(11, 120, 206) !important;
// }

// .grayBorder {
//     border-color: rgba(104,115,140) !important;
// }

// .greenBorder {
//     border-color: rgba(48, 187, 187) !important;
// }

// .pinkBorder {
//     border-color: rgba(216, 27, 96) !important;
// }

// .purpleBorder {
//     border-color: rgba(85, 82, 153) !important;
// }

// .orangeBorder {
//     border-color: rgba(255, 119, 1) !important;
// }

.all {
  border: 2px solid;
}
