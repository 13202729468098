@import './colors.scss';

@mixin label($font-size: 12px, $line-height: 2.8em, $color: $marine-blue, $height: 35px) {
  font-family: Cabin;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
  height: $height;
  border-radius: $height/2;
}
