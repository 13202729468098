@import '../colors.scss';
@import '../button.scss';
.SelectDemo {
	.ui.grid {
		padding-top: 50px;
		.column {
			.button {
				width: 215px;
				min-height: 50px;
				border-radius: 54px;
				background-color: #ff7d7c;
				font-family: Futura;
				font-size: 16px;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.63;
				letter-spacing: normal;
				text-align: center;
				color: #ffffff;
				cursor: auto;
				-webkit-user-select: text; /* Chrome 49+ */
				-moz-user-select: text; /* Firefox 43+ */
				-ms-user-select: text; /* No support yet */
				user-select: text;
			}
		}
	}
	.column {
		.ui.loader {
			display: block !important;
			left: 56% !important;
		}
	}
}
