@import '../colors.scss';
@import '../header.scss';
@import '../button.scss';

.overview {
	font-family: Futura;
	.row > .image {
		margin-top: 65px;
		margin-left: 65px;
		width: 117px;
		height: 30px;
	}
	.clear {
		display: flex;
		flex-direction: row-reverse;
		margin: 24px 64px 28px 0px;
		align-self: flex-end;
		.button {
			width: 142px;
			height: 40px;
			background-color: $bluegrey !important;
			border-radius: 20px;
			font-family: Cabin;
			font-size: 15px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.13;
			letter-spacing: normal;
			text-align: center;
			color: $pale-grey;
		}
		i.icon {
			margin-left: -10px !important;
		}
	}
}
.progress-box {
	display: flex;
	margin: auto;
	margin-bottom: 25px;
	width: 823px;
	height: 100px;
	border-radius: 10px;
	box-shadow: 0 2px 1px 0 $azure-5;
	background-color: $white;

	.ui.progress {
		border-radius: 8px;
		width: 380px;
		margin-top: 42px !important;
		margin-left: 48px !important;
		height: 16px !important;
		background-color: $progress;
	}
	.ui.progress .bar {
		height: 16px !important;
		border-radius: 8px !important;
		background-color: $progress-active !important;
	}
	.ui.progress > .label {
		@include header1(12px !important, normal, 1.67, $blue-grey !important, normal);
	}
}
.progress-status {
	margin-top: 35px;
	margin-left: 40px;
	width: 180px;
	height: 30px;
	@include header1(20px, 500, 1.3, $marine-blue, normal);
}

.overview > .ui.grid {
	margin: auto;
	width: 823px;
}
.ui.grid > .extra {
	margin-top: 24px;
	padding: 0 !important;
}
.ui.grid > .chart {
	min-height: 500px;
	width: 823px !important;
	border-radius: 10px;
	box-shadow: 0 2px 1px 0 $azure-5;
	background-color: $white;
	.loader {
		display: block;
	}
}

.pareto-chart {
	margin-left: 144px;
	margin-top: 24px;
	width: 823px;
	border-radius: 10px;
	box-shadow: 0 2px 1px 0 $azure-5;
	background-color: $white;
}
.overkill {
	width: 358px;
	height: 208px;
	border-radius: 10px;
	box-shadow: 0 2px 1px 0 $azure-5;
	background-color: $white;
	margin-bottom: 24px;
	.ui.progress {
		margin-left: 40px !important;
		margin-top: 20px !important;
		width: 270px;
	}
	.ui.progress .bar {
		height: 10px;
		background-color: $azure;
	}
}
.analysis {
	width: 358px;
	height: 211px;
	border-radius: 10px;
	box-shadow: 0 2px 1px 0 $azure-5;
	background-color: $white;
}
.legend_ {
	display: none;
}
.chart-label {
	margin-top: 28px;
	margin-left: 24px;
	height: 30px;
	@include header1(20px, 500, 1.3, $marine-blue, normal);
}
.chart_ {
	margin: 28px 5px 0px 5px;
}
.overkill-machine {
	margin-left: 40px;
	padding-top: 29px;
	width: 327px;
	height: 30px;
	@include header1(20px, 500, 1.3, $marine-blue, normal);
}
.Over-rejected-by-mac {
	margin-left: 40px;
	margin-top: 36px;
	width: 310px;
	height: 35px;
	@include header1(12px, normal, 1.67, $bluegrey, normal);
}
.progress {
	display: flex;
	.ui.progress {
		background-color: $progress-two;
	}

	.rate {
		margin-left: 8px;
		margin-top: 15px;
	}
}
.closeButton {
	margin-right: 7px;
	margin-top: -3px;
	display: inline !important;
}
.progress-label {
	width: 60px;
	height: 30px;
	transform: rotate(-360deg);
	@include header1(18px, 500, 0.78, $marine-blue, normal);
	text-align: right;
}
.refreshButton {
	width: 20px;
	height: 20px;
	margin-left: 50px;
	margin-top: 40px;
}
.Futher-Analysis {
	padding-top: 47px;
	margin-left: 40px;
	width: 327px;
	height: 30px;
	@include header1(20px, 500, 1.3, $marine-blue, normal);
}
.ui.button.next-overview {
	margin-top: 18%;
	margin-left: 25%;
	@include b-third(117px, 30px, $salmon-20, 13px, 2px);
	@include header1(13px, normal, 2px, $salmon, normal);
}
.ui.labeled.icon.button.next-overview > .icon {
	background-color: unset;
	right: 5px;
}
.ui[class*='right labeled'].icon.button.next-overview {
	padding-left: 2px !important;
	padding-right: 20px !important;
}
