@import './colors.scss';

@mixin b-primary(
  $width: 114px,
  $height: 43px,
  $background-color: $grapefruit-two,
  $font-size: 14px,
  $line-height: normal
) {
  width: $width !important;
  height: $height !important;
  border-radius: $height/2;
  background-color: $background-color;
  font-family: Futura;
  font-size: $font-size;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: $line-height;
  letter-spacing: normal;
  text-align: center;
  color: $white;
  &:hover {
    box-shadow: 0 10px 20px 0 $salmon-45;
    color: $white !important;
    background-color: $salmon-two;
  }
  &:focus {
    box-shadow: 0 10px 20px 0 $salmon-36;
    color: $white !important;
    background-color: $pale-red;
  }
}

@mixin b-secondary(
  $width: 140px,
  $height: 44px,
  $background-color: $grapefruit-two,
  $font-size: 14px,
  $line-height: normal
) {
  width: $width !important;
  height: $height !important;
  border-radius: $height/2;
  background-color: $background-color;
  border: solid 1px $grapefruit;
  text-align: center;
  font-family: Cabin;
  font-size: $font-size;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: $line-height !important;
  letter-spacing: normal;
  color: $grapefruit !important;
  &:hover {
    background-color: $grapefruit-two;
    color: $white !important;
  }
  &::focus {
    background-color: $pale-red;
    color: $white !important;
  }
}
@mixin b-third($width: 110px, $height: 30px, $background: $salmon-20, $font-size: 13px, $line-height: 0.5) {
  width: $width;
  height: $height;
  background: $background;
  font-family: Cabin;
  font-size: $font-size;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  border-radius: 15px;
  line-height: $line-height;
  color: $salmon;
  &:hover {
    box-shadow: 0 10px 20px 0 $salmon-20 !important;
    background-color: $salmon-20-two !important;
  }
  &::focus {
    box-shadow: 0 10px 20px 0 $salmon-40 !important;
    background-color: $salmon-20-two !important;
  }
}

@mixin b-primary-disabled() {
  opacity: 0.4 !important;
  background-color: $ice-blue;
  color: $marine-blue;
}
