@import '../colors.scss';
@import '../header.scss';

.unit-row {
  width: 930px;
  height: 100px;
  border-radius: 10px;
  box-shadow: 0 2px 1px 0 rgba(28, 167, 229, 0.05);
  background-color: $white;
  margin-top: 30px;
  font-family: Cabin;

  div.defect {
    min-width: 70px;
    display: inline-block;
    height: 30px;
    margin: auto;
    // margin-right: 15px;
    // margin-left: 30px;
    line-height: 0;
    padding: 0 10px;
    background-color: #d2ecfa;
    border-radius: 15px;
    background-size: 100% 100%;
    .defect-label {
      font-family: Cabin;
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #1ca7e5;
      padding-left: 5px;
      line-height: 2.4;
    }
    i.icon {
      float: right;
      margin: 0 0 0 10px !important;
    }
  }

  History is off .defectStatus {
    height: 20px;
    line-height: 1.2;
    color: $tealish;
  }
  .defectStatus.Incorrect {
    color: $salmon;
  }
  .defectClass {
    display: flex;
  }
  .defectIcon {
    padding-top: 20px;
    display: flex;
  }
  .closeIcon {
    padding-right: 8px;
    height: 15px;
    border-right: solid 1px $bluegrey;
    .ui.dropdown > .dropdown.icon {
      display: none;
    }
  }
  .rightIcon {
    padding-left: 5px;
  }
  .label1 {
    width: 30px !important;
    height: 30px;
    margin-top: 35px !important;
    margin-left: 35px;
  }
  .column {
    width: auto;
  }
  .ui.dropdown .menu > .divider {
    width: 245px;
    height: 1px;
    border: solid 1px $steel-grey-20;
    background-color: $white;
  }
  .defect-image {
    margin-left: 15px;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    cursor: zoom-in;
  }
  .image-all {
    width: 155px;
    .ui.image {
      margin: auto;
      width: 60px;
      height: 60px;
      border-radius: 10px;
      cursor: zoom-in;
    }
  }
  .image-group {
    display: flex;
    flex-direction: column;
  }
  .image-group .ui.image {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    float: right;
    margin-bottom: 5px;
  }
  .defect-name {
    display: block;
    // width: 200px;
    word-break: break-all;
    margin: auto;
    @include header1(12px, normal, 1.67, $bluegrey, normal);
  }
  .original {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    float: left;
  }

  .ui.image {
    margin: auto;
  }
}
.ui.button.next-analyse {
  width: 117px;
  height: 30px;
  @include header1(13px, normal, 2px, $salmon, normal);
}
.ui.labeled.icon.button.next-analyse > .icon {
  background-color: unset;
  right: 5px;
}
.ui[class*='right labeled'].icon.button.next-analyse {
  padding-left: 2px !important;
  padding-right: 20px !important;
}
.flash {
  .ui.dropdown .menu {
    left: -270px;
    top: 43px;
    border-radius: 10px;
  }
}
.fdd {
  display: flex;
  flex-direction: row;
  width: 333px;
  height: 63px;
  .fddOne {
    padding: 12px 0 12px 24px;
    .fddImage {
      width: 53px;
      height: 39px;
      object-fit: contain;
    }
  }
  .fddTwo {
    padding: 12px 24px 18px 16px;
    .fddData {
      width: 216px;
      height: 33px;
      @include header2(16px, 500, 2.06, $marine-blue, normal);
    }
  }
}

.extraClass {
  pointer-events: auto !important;
  opacity: 1 !important;
  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}
