@import '../colors.scss';
@import '../button.scss';
@import '../header.scss';

.overall {
  .select-filter-title {
    @include header1(16px, 600, 33px, $marine-blue, normal);
  }

  .cards-list {
    margin-left: 49.8px;
    margin-top: 22px !important;
    width: 930px;
    height: 284px;
    background: $white;
    border-radius: 5px;
    box-shadow: 0 1px 15px 0 $black-6;
    background-color: $white;
    .confusion-title {
      width: 427px;
      height: 33px;
      margin: 20.5px 0 0 35px;
      @include header1(18px, 500, 2, $marine-blue, normal);
      .show-rows {
        margin-left: 30px;
        width: 125px;
        .ui.selection.dropdown {
          min-width: 130px;
          height: 30px !important;
          min-height: auto;
          border-radius: 3px;
          box-shadow: 0 2px 20px 0 $black-5;
          border: solid 1px $marine-blue-8;
          background-color: $white;
          padding: 0;
          padding-left: 20px;
          line-height: 1.2;
          .text {
            @include header1(14px, normal, 2.14, $marine-blue, normal);
          }
          .icon {
            top: unset;
            line-height: 1.5;
          }
        }
      }
    }
    .cards {
      width: 900px;
      margin: auto;
      padding-bottom: 20px;
      .card {
        width: 260px;
        height: 130px;
        border-radius: 7.5px;
        box-shadow: 0 1px 7.5px 0 $dark-sky-blue-7;
        border: solid 0.5px $azure-14;
        border-left: 4px solid $azure;
        .content {
          display: flex;
          flex-direction: column;
          .header {
            margin: 0;
            padding-top: 0 !important;
            opacity: 0.5;
            @include header1(18px, normal, 1.83, $marine-blue, normal);
          }
          .meta {
            @include header1(40px, 500, 0.83, #23ace3, normal);
            background-clip: text;
            .per {
              font-size: 18px;
              line-height: 0;
              font-weight: 100;
            }
          }
        }
      }
    }
    .title {
      margin: 20.5px 0px 0px 35px;
      @include header2(18px, 500, 1.83, $marine-blue, normal);
    }
  }
  .learn-more {
    width: 465px;
    height: 182px;
    margin-top: 40px !important;
    margin-left: 49.8px;
    border-radius: 10px;
    box-shadow: 0 2px 30px 0 $black-6;
    background-color: $white;
    .title {
      margin: 20.5px 0px 0px 35px;
      @include header2(18px, 500, 33px, $marine-blue, normal);
    }
    .button {
      @include b-secondary(140px, 44px, transparent, 14px, normal);
      margin-left: 35px;
    }
    .button:focus {
      background-color: $pale-red;
      color: $white;
    }
  }
}
.ui.modal.information-modal {
  width: 930px;
  height: 903px;
  border-radius: 15px;

  box-shadow: 0 2px 40px 0 $black-8;
  background-color: $white;
  .content {
    height: auto;
    .ui.grid {
      background-color: $white;
      .title {
        font-size: 18px;
      }
      .close {
        flex-direction: row-reverse;
      }
      .row1 {
        height: 91px;
        font-family: Cabin;
        font-size: 14px;
        line-height: 13px;
        color: $marine-blue;
        text-align: left;
        line-height: 25px;
        margin-left: 40px;
      }
      .row2 {
        height: 26px;
        font-family: Cabin;
        font-size: 14px;
        line-height: 13px;
        color: $marine-blue;
        margin-top: 40px;
        margin-left: 40px;
        text-align: left;
      }
      .row3 {
        width: 844px;
        height: 248px;
        border-radius: 2.5px;
        background-color: $pale-grey;
        margin-left: 43px;
        margin-right: 46px;
        margin-top: 20px;
        .info-table {
          width: 791px;
          height: 198px;
          margin-left: 27px;
          .table-cell {
            height: 49px;
            width: 59px;
            font-family: Futura;
            font-size: 18px;
            font-weight: 500;
            color: $marine-blue;
          }
        }
      }
      .row4 {
        height: 44px;
        font-family: Cabin;
        font-size: 14px;
        line-height: 22px;
        color: $marine-blue;
        margin-left: 40px;
        margin-top: 41px;
        text-align: left;
      }
      .row5 {
        height: 50px;
        font-family: Cabin;
        font-size: 14px;
        line-height: 25px;
        color: $marine-blue;
        margin-left: 40px;
        margin-top: 15px;
        text-align: left;
      }
      .row6 {
        height: 125px;
        font-family: Cabin;
        font-size: 14px;
        line-height: 25px;
        color: $marine-blue;
        margin-left: 40px;
        margin-top: 18px;
        text-align: left;
      }
      .row7 {
        height: 50px;
        font-family: Cabin;
        font-size: 14px;
        line-height: 25px;
        color: $marine-blue;
        margin-left: 40px;
        margin-top: 18px;
        text-align: left;
        margin-bottom: 60px;
      }
      .ui.button {
        width: 114px;
        height: 40px;
        border-radius: 22px;
        border: solid 1px $grapefruit;
        background: transparent;
        font-family: Cabin;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $grapefruit;
      }
    }
  }
}
.modals.dimmer .ui.scrolling.modal {
  // margin: 125px 0 183px 0 !important;
}
.ui.card > .content:after,
.ui.cards > .card > .content:after {
  content: none !important;
}
.ui.card:after,
.ui.cards:after {
  content: none !important;
}
