@import '../colors.scss';

.history.ui.grid {
  width: 900px;
}
.history.ui.grid > .row {
  padding: 0px;
  margin-top: 80px;
}
.history > .row > p {
  opacity: 0.5;
  font-family: Cabin;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: $marine-blue;
}
