@import '../../assests/styles/header.scss';

.ui.modal .scrolling.content.modal-viaconfigure-content {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  .configuration-header {
    position: relative;
    margin-bottom: 20px;
    .title {
      position: absolute;
      top: 40%;
      left: 45%;
      @include header1(22px, 500, 1.38, #02435d, normal);
    }
    .close {
      margin-left: 97%;
    }
  }
  .ui.input > input {
    box-shadow: 0 2px 1px 0 rgba(28, 167, 229, 0.05);
    padding: 0 5px;
    height: 40px;
    font-family: Cabin;
    font-size: 15px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    border-radius: 10px;
    width: 100%;
  }
  .ui.input > input:focus {
    border-color: #80a1ae;
  }
  .ui.button {
    width: 50px;
    height: 38px;
    background-color: #80a1ae !important;
    font-family: Cabin;
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    padding: 0;
    border-radius: 15px;
  }
  .add-delete-attribute {
    margin-top: 25px;
    .add-attribute {
      margin-left: 15px;
    }
    .delete-attribute {
      margin-left: 1px;
    }
  }
  .ui.selection.dropdown {
    border-radius: 10px;
  }
  .ui.selection.active.dropdown {
    border-color: #80a1ae !important;
  }
  .ui.selection.dropdown.dropdown-attribute {
    margin-top: 25px;
  }
  .ui.grid.grid-bottom {
    margin-top: 20px;

    .ui.input {
      width: 100%;
    }
    .row {
      align-items: center;
    }
    .ui.label {
      max-height: 25px;
      background-color: #80a1ae;
      color: #fff;
    }
    .ui.checkbox {
      height: 40px;
      margin-left: 7px;
      label {
        margin-top: 12px;
      }
    }
    .new_option_id_entry {
      display: flex;
      flex-direction: column;

      .ui.grid {
        margin-top: 5px;
      }
      .add-attribute-property-option {
        margin-top: 10px;
      }
    }
  }
}
.ui.modal > .content {
  background: inherit !important;
}
