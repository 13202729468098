@import '../colors.scss';
@import '../header.scss';

.confusion-bg {
	width: 80%;
	height: auto;

	.confusion {
		width: 100%;
		min-height: 575px;
		padding-bottom: 50px;
		border-radius: 10px;
		box-shadow: 0 2px 30px 0 $black-6;
		background-color: $white;
		.ui.padded.grid:not(.vertically):not(.horizontally).confusion-grid {
			margin: 20px !important;
		}
		.ui.text.loader {
			display: block;
			top: 50%;
			left: 40%;
		}
		.confusion-grid {
			display: flex;
			padding: 10px;
			.v-nav {
				margin-top: 160px;
				max-width: 90px !important;
				padding: 0;
				margin-right: 10px;
				.title {
					margin: 0;
					img {
						padding-top: 20px;
						width: 5px;
						margin: auto;
					}
					.info-icon {
						margin-bottom: 15px;
						writing-mode: horizontal-tb;
					}
				}
				.row {
					border: none;
					.defect {
						width: 90px;
						height: 40px;
						border-radius: 3px;
						box-shadow: 0 2px 20px 0 $black-5;
						border: solid 1px $marine-blue-8;
						background-color: $white;
						@include header1(12px, 500, 2.5, $marine-blue, normal);
						text-align: center;
						.menu {
							height: 300px;
							overflow-y: scroll;
							.item {
								@include header1(12px, 500, 0.5, $marine-blue, normal);
							}
						}
					}
				}
				.title {
					margin-bottom: 20px;
					display: flex;
					@include header1(14px, 500, 8, $marine-blue, normal);
					writing-mode: vertical-rl;
					text-orientation: mixed;
					.info {
						width: 12.4px;
						height: 12.4px;
						margin: auto;
						margin-bottom: 5px;
					}
				}
				.nav {
					width: 40px;
					height: 40px;
					border-radius: 5px;
					margin: 0px 0px 10px 70px;
					background-color: $dark-sky-blue-three-20;
					i.icon,
					i.icons {
						font-size: 1.8em;
						margin: 9px 0px 0px 7px;
					}
				}
				.disable {
					pointer-events: none;
					opacity: 0.5;
				}
			}
			.matrix.ui.grid {
				.loader {
					width: 500px;
					height: 402px;
				}

				.h-nav {
					margin-bottom: 11px;
					width: 100% !important;
					.column {
						border: none;
						line-height: normal;
						.defect {
							float: right;
							width: 112px;
							height: 40px;
							border-radius: 3px;
							box-shadow: 0 2px 20px 0 $black-5;
							border: solid 1px $marine-blue-8;
							background-color: $white;
							font-family: Cabin;
							font-size: 12px;
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2.7;
							letter-spacing: normal;
							color: $marine-blue;
							text-align: center;
							.menu {
								height: 300px;
								overflow-y: scroll;
								.item {
									@include header1(12px, 500, 0.5, $marine-blue, normal);
								}
							}
						}
					}
					.show-rows {
						display: flex;
						align-items: center;
						justify-content: flex-end;
						font-family: Cabin;
						font-size: 14px;
						font-weight: normal;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.21;
						letter-spacing: normal;
						color: $marine-blue;
						.ui.dropdown {
							margin-left: 20px;
							width: 45px;
							height: 36px;
							border-radius: 3px;
							box-shadow: 0 2px 20px 0 $black-5;
							border: solid 1px $marine-blue-8;
							background-color: $white;
							font-family: Cabin;
							font-size: 12px;
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2.7;
							letter-spacing: normal;
							color: $marine-blue;
							text-align: center;
							.menu {
								height: auto;
								.item {
									@include header1(12px, 500, 0.5, $marine-blue, normal);
								}
							}
						}
					}
					.title {
						padding-right: 100px;
						display: flex;
						padding-top: 15px;
						width: 55px;
						align-items: center;
						@include header1(14px, 500, 0.9, $marine-blue, normal);
						.info {
							margin-right: 5px;
							width: 12.4px;
							height: 12.4px;
						}
						img {
							margin-left: 20px;
						}
					}
					.nav {
						width: 40px;
						height: 40px;
						border-radius: 5px;
						margin-right: 10px;
						background-color: $dark-sky-blue-three-20;
						i.icon,
						i.icons {
							font-size: 1.8em;
							margin: 9px 0px 0px 0px;
						}
					}
					.disable {
						pointer-events: none;
						opacity: 0.5;
					}
				}
				// .precision {
				//   width: 140px;
				//   text-align: center;
				//   margin: 0px 0px 0px 30px;
				//   padding: 0;
				//   .title {
				//     margin: 0;
				//     @include header1(12px, 500, normal, $marine-blue, normal);
				//     .info {
				//       width: 17px;
				//       height: 17px;
				//       float: right;
				//       margin-right: 5px;
				//       margin-top: 12px;
				//     }
				//   }
				//   .row {
				//     line-height: 3.4;
				//     border: solid 1px $marine-blue-13;
				//     height: 40px;
				//     background: $dark-sky-blue-three-20;
				//   }
				// }
				.container {
					margin: inherit;
					margin-left: 5px;
					display: grid;
					justify-content: space-evenly;
					height: 720px;
					width: 100%;
					align-content: space-between;
					grid-template-columns: [col-start] 41px [line2] 574px [line3] 30px [line4] 41px [col-end];
					grid-template-rows: [row-start] 41px [row2] 574px [row3] 30px [row4] 41px [row-end];
					grid-template-areas:
						'. actualData . .'
						'predicted gridData pTotal precision'
						'. aTotal . .'
						'. recall . .';
					text-align: center;
					font-family: Cabin;
					font-size: 12px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.67;
					letter-spacing: normal;
					color: $marine-blue;
					.actualData {
						overflow: hidden;
						display: grid;
						grid-area: row-start / line2 / row2 / line3;
						// grid-template-columns: repeat(23, 41px);
						grid-template-rows: repeat(1, 41px);
						background-color: rgba(39, 174, 224, 0.18);
						border-radius: 3px;
						.dataCell {
							text-align: center;
							border: solid 1px $marine-blue-8;
							line-height: 3;
						}
					}
					.predicted {
						overflow: hidden;
						display: grid;
						grid-area: row2 / col-start / row3 / line2;
						grid-template-columns: repeat(1, 41px);
						// grid-template-rows: repeat(20, 41px);
						background-color: rgba(39, 174, 224, 0.18);
						border-radius: 3px;
						.dataCell {
							border: solid 1px $marine-blue-8;
							line-height: 3;
						}
					}
					.data-container {
						cursor: pointer;
						overflow: scroll;
						display: grid;
						grid-area: row2 / line2 / row3 / line3;

						width: 574px;
						box-shadow: 0 2px 20px 0 rgba(40, 175, 224, 0.19);
						.dataGrid {
							text-align: center;
							line-height: 3;
							border: solid 1px $marine-blue-8;
						}
						.vSeparate {
							border-right: 1px solid $marine-blue;
						}
						.hSeparate {
							border-bottom: 1px solid $marine-blue;
						}
						.diagonal {
							background-color: rgba(245, 108, 108, 0.3);
						}
					}
					::-webkit-scrollbar {
						width: 6px;
					}
					::-webkit-scrollbar:horizontal {
						height: 6px;
					}
					/* Track */
					::-webkit-scrollbar-track,
					::-webkit-scrollbar-track:horizontal {
						box-shadow: inset 0 0 5px $dark-sky-blue-18;
						border-radius: 10px;
					}

					/* Handle */
					::-webkit-scrollbar-thumb,
					::-webkit-scrollbar-thumb:horizontal {
						background: #1ca7e5;
						border-radius: 10px;
					}

					/* Handle on hover */
					::-webkit-scrollbar-thumb:hover,
					::-webkit-scrollbar-thumb:horizontal:hover {
						background: #1ca7e5;
					}
					.pTotal {
						overflow: hidden;
						display: grid;
						grid-area: row2 / line3 / row3 / line4;
						grid-template-columns: repeat(1, 30px);
						// grid-template-rows: repeat(20, 41px);
						background-color: rgba(39, 174, 224, 0.18);
						border-radius: 3px;
						.pTotalCell {
							text-align: center;
							border: solid 1px $marine-blue-8;
							line-height: 3;
						}
					}
					.precision {
						overflow: hidden;
						display: grid;
						grid-area: row2 / line4 / row3 / col-end;
						grid-template-columns: repeat(1, 40px);
						// grid-template-rows: repeat(20, 40px);
						background-color: rgba(39, 174, 224, 0.18);
						border-radius: 3px;
						width: 64px;
						.dataCell {
							text-align: center;
							border: solid 1px $marine-blue-8;
							line-height: 3;
							width: 64px;
						}
					}
					.aTotal {
						overflow: hidden;
						display: grid;
						grid-area: row3 / line2 / row4 / line3;
						// grid-template-columns: repeat(20, 41px);
						grid-template-rows: repeat(1, 41px);
						background-color: rgba(39, 174, 224, 0.18);
						border-radius: 3px;
						.aTotalCell {
							text-align: center;
							border: solid 1px $marine-blue-8;
							line-height: 3;
						}
					}
					.recall {
						overflow: hidden;
						display: grid;
						grid-area: row4 / line2 / row-end / line3;
						// grid-template-columns: repeat(23, 40px);
						grid-template-rows: repeat(1, 41px);
						background-color: rgba(39, 174, 224, 0.18);
						border-radius: 3px;
						.dataCell {
							text-align: center;
							border: solid 1px $marine-blue-8;
							line-height: 3;
						}
					}
					.pEmpty {
						width: 30px;
						height: 42px;
						line-height: 1;
						font-size: 10px;
					}
					.empty2 {
						font-family: Cabin;
						font-size: 10px;
						font-weight: normal;
						font-stretch: normal;
						font-style: normal;
						line-height: 1;
						letter-spacing: normal;
						color: $marine-blue;
						width: 54px;
					}
					.empty3 {
						margin-left: -25px;
						font-family: Cabin;
						font-size: 11px;
						display: flex;
						font-weight: normal;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.25;
						letter-spacing: normal;
						color: $marine-blue;
						width: 80px;
						align-items: center;
						.info-icon {
							margin-right: -10px;
						}
					}
					.aEmpty {
						margin-left: -15px;
						width: 70px;
						font-size: 10px;
					}
				}
			}

			.confusion-title {
				width: 427px;
				height: 33px;
				margin-top: 30px;
				margin-bottom: 30px;
				font-size: 20px;
				font-weight: 500;
				color: $marine-blue;
				line-height: 2;
				.download {
					margin-left: 25px;
					.box-button {
						@include b-primary(140px, 44px, $salmon, 14px, 1);
					}
				}
				.show-rows {
					margin-left: 30px;
					width: 125px;
					.ui.selection.dropdown {
						min-width: 130px;
						height: 30px !important;
						min-height: auto;
						border-radius: 3px;
						box-shadow: 0 2px 20px 0 $black-5;
						border: solid 1px $marine-blue-8;
						background-color: $white;
						padding: 0;
						padding-left: 20px;
						margin-left: 10px;
						line-height: 1.2;
						.text {
							@include header1(14px, normal, 2.14, $marine-blue, normal);
						}
						.icon {
							top: unset;
							line-height: 1.5;
						}
					}
				}
			}
			.confusion-matrix {
				margin-top: 40px;
			}
		}
		.trend {
			margin-left: 0px !important;
			margin-top: 68px !important;
			width: 465px;
			height: 182px;
			border-radius: 10px;
			box-shadow: 0 2px 30px 0 $black-6;
			background-color: $white;
			.trend-row {
				.trend-row-column {
					width: 232px !important;
					font-family: Futura;
					font-size: 18px;
					font-weight: 500;
					line-height: 33px;
					color: $marine-blue;
					margin-top: 20px !important;
					margin-left: 40px;
				}
				.trend-row-column2 {
					width: 232px;
				}
			}
			.trend-row2 {
				.trend-row2-column {
					width: 232px !important;
				}
				.trend-row2-column2 {
					width: 232px;
				}
			}
		}
	}
}
.extraClass {
	margin-top: 10px !important;
	text-align: center;
	@include header1(12px, 500, normal, $marine-blue !important, normal);
}
.__react_component_tooltip.place-left {
	margin-left: 10px !important;
}
.__react_component_tooltip {
	padding: 0 21px !important;
}

.info-icon {
	margin: auto;
	background-image: linear-gradient(169deg, $azure -10%, $seafoam-blue 158%);
	color: white;
	width: 16px;
	border-radius: 50%;
	/* border: 1px; */
	align-content: center;
	text-align: center;
	height: 16px;
	line-height: 16px;
}

.confusion-tip {
	margin-top: -2px;
}
