@import '../colors.scss';
@import '../button.scss';
@import '../header.scss';
@import '../label.scss';

.feedback {
	font-family: Futura;
	.dropdown-menu {
		height: auto !important	;
	}
	.selected-filters {
		width: 90% !important;
		margin-top: 30px;
		.column-box2 {
			.select-filter-title {
				height: 36px;
				display: flex;
				.selected-title {
					// width: 223px;
					height: 33px;
					font-size: 14px !important;
					line-height: 33px;
					color: $marine-blue;
					display: flex;
					font-family: Cabin;
					.show-filter-btn {
						width: 148px;
						height: 18px;
						font-family: Cabin;
						font-size: 14px;
						font-weight: 500;
						color: $grapefruit-two;
						background: transparent;
					}
				}
			}
			.hr {
				height: 1px;
				opacity: 0.5;
				border: solid 1px #979797;
			}
			.selected-filter-results-list {
				display: flex;
				border-top: 1px solid $warm-grey-three;
				.selected-result {
					width: 214px;
					margin-left: 23px;
					margin-left: 14px;
					margin-top: 14px;
					.selected-filter-title {
						width: 214px;
						height: 33px;
						@include header1(16px, 500, 33px, $marine-blue, normal);
						margin-bottom: 5px;
						margin-top: 5px;
					}
					.selected-filter-results {
						min-width: 191px;
						height: auto;
						display: flex;
						flex-wrap: wrap;
						.label {
							display: flex;
							margin: 0 5px 5px 5px;
							padding: 0 15px;
							background: $azure-1;
							@include label(12px, 2.8em, $marine-blue, 35px);
							.close {
								cursor: pointer;
								float: right;
								padding-left: 10px;
							}
						}
					}
				}
			}
			.vr {
				width: 1px;
				height: 78px;
				opacity: 0.3;
				border: solid 1px #979797;
				margin-top: 24px;
			}
			.button {
				margin-top: 50px;
				margin-left: 10px;
				width: 40px;
				height: 40px;
				// opacity: 0.2;
				border-radius: 5px;
				// background: $dark-sky-blue-three;
				.icon {
					margin: -14.5px !important;
					color: $marine-blue;
				}
			}
			.selected {
				background-color: $dark-sky-blue-20;
			}
			.unselected {
				background-color: $white-four;
			}
			.button::focus {
				background-color: $dark-sky-blue-three;
			}
		}
	}
	.link {
		align-self: start;
		margin: 25px 0 0 5%;
		img {
			vertical-align: middle;
		}
		a {
			margin-left: 10px;
			font-family: FuturaBT;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 2.5;
			letter-spacing: normal;
			color: $marine-blue;
		}
	}
	.title {
		display: flex;
		flex-direction: row;
		.sub-title {
			margin-top: 10px;
			padding: 15px 0px 0px 0px;
			@include header2(18px, 500, 1.83, $marine-blue, normal);
		}
		.box-button {
			@include b-primary(140px, 44px, $salmon, 14px, 1);
			float: right;
			margin-left: 380px;
			margin-top: 25px;
		}
	}
	.accuracy-card {
		width: 130px;
		height: 66px;
		border-radius: 3px;
		border: solid 1px $azure-14;
		background-color: $white;
		align-self: flex-start;
		margin-left: 5%;
		margin-top: 3%;
		border-left: 2px solid $azure;
		background-image: url('../../images/bg.jpeg');
		padding-left: 18px;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		.title {
			width: 51px;
			height: 33px;
			font-family: Futura;
			font-size: 12px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 2.75;
			letter-spacing: normal;
			color: $marine-blue;
		}
		.score {
			@include header1(20px, 500, 0.83, #23ace3, normal);
			background-clip: text;
			.per {
				font-size: 18px;
				line-height: 0;
				font-weight: 100;
			}
		}
	}
	.misclass-analysis {
		width: 100%;
		margin-top: 15px;
		padding-bottom: 20px;
		align-self: flex-start;
		margin-left: 6.5%;
		.ui.loader {
			margin-top: 50px;
			display: block;
		}
		.load.row {
			margin-top: 30px;
			.ui.button {
				margin: auto;
				@include b-primary(140px, 44px, $grapefruit-two, 12px, normal);
				border: solid 2px $grapefruit-two;
				font-family: Futura;
			}
		}
		.select-filter {
			display: block;
			margin-left: 53px;
			.filter-title {
				width: 103px;
				height: 33px;
				font-family: FuturaBT;
				font-size: 16px;
				line-height: 33px;
				color: $marine-blue;
			}
		}
		.ui.dropdown {
			.menu {
				margin-left: 40px;
				width: 215px;
				height: 250px;
				.item {
					@include header1(12px, 500, 0.5, $marine-blue, normal);
				}
			}
		}
		.ui.multiple.dropdown {
			margin-left: 30px;
			margin-top: 20px;
		}
		.unit.ui.dropdown {
			.menu {
				overflow-y: scroll;
			}
		}
		.horizontal-line {
			width: 850px;
			height: 1px;
			opacity: 0.5;
			border: solid 1px #979797;
		}
		.drop-btn {
			text-align: left;
			font-family: Cabin;
			font-size: 14px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;
			color: $marine-blue;
			width: 215px;
			height: 50px;
			border-radius: 3px;
			box-shadow: 0 2px 20px 0 $black-5;
			border: solid 1px $marine-blue-8;
			background-color: $white;
		}
		.ui[class*='right labeled'].icon.button > .icon {
			background: $white;
			color: $marine-blue;
		}
		.line {
			width: 1px;
			height: 78px;
			opacity: 0.3;
			border: solid 1px #979797;
			margin-left: 40px;
		}
		.line2 {
			width: 1px;
			height: 78px;
			opacity: 0.3;
			border: solid 1px #979797;
			margin-left: -80px;
		}

		.column-box2 {
			margin-left: 40px;
			.select-filter-title {
				width: 850px;
				height: 36px;
				display: flex;
				.selected-title {
					// width: 223px;
					height: 33px;
					font-size: 14px !important;
					line-height: 33px;
					color: $marine-blue;
					display: flex;
					font-family: Cabin;
					.show-filter-btn {
						width: 148px;
						height: 18px;
						font-family: Cabin;
						font-size: 14px;
						font-weight: 500;
						color: $grapefruit-two;
						background: transparent;
					}
				}
			}
			.hr {
				width: 850px;
				height: 1px;
				opacity: 0.5;
				border: solid 1px #979797;
			}
			.selected-filter-results-list {
				width: 850px;
				display: flex;
				border-top: 1px solid $warm-grey-three;
				.selected-result {
					width: 214px;
					margin-left: 23px;
					margin-left: 14px;
					margin-top: 14px;
					.selected-filter-title {
						width: 214px;
						height: 33px;
						@include header1(16px, 500, 33px, $marine-blue, normal);
						margin-bottom: 5px;
						margin-top: 5px;
					}
					.selected-filter-results {
						min-width: 191px;
						height: auto;
						display: flex;
						flex-wrap: wrap;
						.label {
							display: flex;
							margin: 0 5px 5px 5px;
							padding: 0 15px;
							background: $azure-1;
							@include label(12px, 2.8em, $marine-blue, 35px);
							.close {
								cursor: pointer;
								float: right;
								padding-left: 10px;
							}
						}
					}
				}
			}
			.vr {
				width: 1px;
				height: 78px;
				opacity: 0.3;
				border: solid 1px #979797;
				margin-top: 24px;
			}
			.button {
				margin-top: 50px;
				margin-left: 10px;
				width: 40px;
				height: 40px;
				// opacity: 0.2;
				border-radius: 5px;
				// background: $dark-sky-blue-three;
				.icon {
					margin: -14.5px !important;
					color: $marine-blue;
				}
			}
			.selected {
				background-color: $dark-sky-blue-20;
			}
			.unselected {
				background-color: $white-four;
			}
			.button::focus {
				background-color: $dark-sky-blue-three;
			}
		}

		.show-result {
			width: 190px;
			height: 65px;
			font-family: Futura;
			font-size: 18px;
			line-height: 33px;
			color: $marine-blue;
			margin-left: 59px;
			margin-top: 10px;
		}
		.ui.table {
			width: 850px;
			margin-left: 66px;
			height: auto;
			border-collapse: separate;
			border-spacing: 0 5px;
			border: none;
		}
		.ui.table thead th {
			box-shadow: none;
			font-family: Cabin;
			font-size: 16px;
			font-weight: 500;
			color: $marine-blue;
			background: transparent;
			border: none;
		}
		th {
			border: none;
		}
		td {
			border: solid 0.5px rgba(112, 112, 112, 0.36) !important;
		}
		td > img {
			width: 54px;
			height: 54px;
			border-radius: 10px;
			margin: auto;
		}
		.misclass-row {
			height: 70px;
			font-family: Cabin;
			font-size: 12px;
			font-weight: 500;
			line-height: 18px;
			color: $marine-blue;
		}

		.pagination {
			.show.column {
				margin-left: 30px;
				width: 125px;
				.row {
					.label {
						background-color: $white;
						@include header1(15px, normal, 1.13, $marine-blue, normal);
					}
					input {
						width: 40px;
						height: 40px;
						object-fit: contain;
						border-radius: 4px;
						box-shadow: 0 2px 1px 0 $azure-5;
						border: solid 0.5px $warm-grey-two;
						@include header1(15px, normal, 1.13, $marine-blue, normal);
						font-family: Cabin;
					}
				}
			}
			.goto.column {
				width: 200px;
				.row {
					.label {
						background-color: $white;
						@include header1(15px, normal, 1.13, $marine-blue, normal);
					}
					input {
						width: 40px;
						height: 40px;
						object-fit: contain;
						border-radius: 4px;
						box-shadow: 0 2px 1px 0 $azure-5;
						border: solid 0.5px $warm-grey-two;
						@include header1(15xp, normal, 1.13, $marine-blue, normal);
					}
				}
			}
			.navigation.column {
				margin-left: 380px;
				.buttons {
					.left {
						margin-right: 10px;
					}
					.left,
					.right {
						border-radius: 5px;
						background-color: $dark-sky-blue-30;
						i {
							background-color: rgba(51, 182, 220, 0);
						}
					}
				}
			}
		}
	}
	.displayDrop {
		width: 283px !important;
		height: 245px !important;
		box-shadow: 0 2px 20px 0 $black-11;
		background-color: $white;
		.displayMenu {
			width: 252px;
			height: 209px;
			margin: 11px 11px 25px 20px;
			.dropTitle {
				width: 252px;
				height: 30px;
				margin: 10px 0 0 0;
				@include header1(16px, 600, 30px, $marine-blue, normal);
			}
			.dropRadioBtn {
				width: 252px;
				height: 96px;
				margin: 13px 0 0 0;
				font-family: Cabin;

				.form {
					.field {
						.ui.checkbox {
							label {
								@include header1(14px, normal, normal, $marine-blue !important, normal);
							}
						}
					}
				}
			}
			.applyBtn {
				margin: 20px 0 0 0;
				@include b-secondary(114px, 40px, transparent, 12px, normal);
			}
			.applyBtn:hover {
				width: 114px;
				height: 40px;
				border-radius: 22px;
				background-color: $grapefruit-two;
				color: $white;
			}
			.applyBtn:focus {
				background-color: $pale-red;
				color: $white;
			}
		}
	}
	.ui.checkbox .box:before,
	.ui.checkbox label:before {
		border: 2px solid $azure;
	}
	.ui.checkbox input:checked ~ .box:before,
	.ui.checkbox input:checked ~ label:before {
		border: 2px solid $azure;
	}
	.ui.checkbox input:checked ~ .ui.checkbox {
		background-color: red;
	}
	.ui.radio.checkbox label:after {
		background-color: $azure !important;
		// border: 2px solid $azure;
	}
	.selected-cells {
		display: grid;
		align-self: start;
		grid-template-columns: 110px 100px 100px 130px auto [end];
		grid-template-rows: 60px;
		width: 80%;
		margin-left: 5%;
		margin-top: 25px;
		border-radius: 7px;
		border: solid 1px $dark-sky-blue-66;
		background-color: #2eb3de10;
		font-family: FuturaBT;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.67;
		letter-spacing: normal;
		color: $marine-blue;
		align-items: center;
		transition: height 0.5s;
		.heading {
			text-align: center;
			font-family: Futura;
			font-size: 16px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.88;
			letter-spacing: normal;
			color: $marine-blue;
		}
		.action,
		.action-last {
			cursor: pointer;
			display: flex;
			justify-self: center;
			padding-right: 15px;
			img {
				float: left;
				margin-right: 5px;
			}
			.analyze {
				margin-top: 3px;
			}
		}
		.action {
			border-right: solid 1px $warm-grey-three;
		}
		.close {
			width: 10px;
			justify-self: end;
			cursor: pointer;
			margin: 0 20px 0 0;
		}
	}
	.select-all {
		display: flex;
		align-self: start;
		width: 80%;
		margin: 25px 0 0 5%;
		justify-content: space-between;
		.feedback-grp {
			width: 300px;
			display: flex;
			justify-content: space-evenly;
			span {
				background-image: linear-gradient(111deg, $azure 22%, $seafoam-blue 158%);
				font-family: Futura;
				font-size: 16px;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				-webkit-background-clip: text;
				background-clip: text;
				-webkit-text-fill-color: transparent;
			}
			div {
				width: 1px;
				height: 26px;
				opacity: 0.3;
				border: solid 1px $warm-grey-three;
			}
		}
		.b-contain *,
		.b-contain *::before,
		.b-contain *::after {
			box-sizing: content-box !important;
		}

		.b-contain input {
			position: absolute;
			z-index: -1;
			opacity: 0;
		}

		.b-contain span {
			line-height: 2;
			font-size: 1rem;
			font-family: inherit;
		}

		.b-contain {
			display: table;
			position: relative;
			padding-left: 2.8rem;
			cursor: pointer;
			margin-bottom: 0.5rem;
			font-family: FuturaBT;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.43;
			letter-spacing: normal;
			color: $marine-blue;
		}

		.b-contain input[type='checkbox'] ~ .b-input {
			position: absolute;
			top: 2px;
			left: 2px;
			width: 22px;
			height: 22px;
			border-radius: 5px;
			background: $white;
			// transition: background 250ms;
			border: 2px solid $azure;
		}
		.b-contain input[type='checkbox']::after {
			border: none;
		}
		.b-contain input[type='checkbox'] ~ .b-input::after {
			content: '';
			position: absolute;
			display: none;
			left: 0.68rem;
			top: 0.2rem;
			width: 0.4rem;
			height: 1rem;
			border: 1px solid $white;
			border-width: 0 2px 2px 0;
			transition: background 250ms;
			transform: rotate(45deg);
		}

		.b-contain input:checked ~ .b-input {
			display: block;
			width: 25px;
			height: 25px;
			border: none;
		}
		.b-contain input:checked ~ .b-input::after {
			display: block;
		}

		.b-contain input:checked ~ .b-input {
			background: linear-gradient(149deg, $azure 11%, $seafoam-blue 112%);
		}

		.b-contain input[type='checkbox']:disabled ~ .b-input {
			background: rgba(241, 245, 248, 1);
			border-color: $azure;
			opacity: 0.6;
			cursor: not-allowed;
		}

		.b-contain input:checked:focus ~ .b-input,
		.b-contain:hover input:not([disabled]):checked ~ .b-input {
			background: linear-gradient(149deg, $azure 11%, $seafoam-blue 112%);
			width: 25px;
			height: 25px;
			border: none;
		}
	}
	.filters {
		display: flex;
		flex-direction: row;
		align-self: start;
		flex-wrap: wrap;
		align-items: stretch;
		padding: 0;
		margin-left: 5%;
		margin-top: 10px;
		.filter-row {
			position: relative;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: inherit;
			align-items: stretch;
			width: 100% !important;
			.filter-label {
				bottom: 78px;
				width: 100%;
				height: 18px;
				font-family: Futura;
				font-size: 16px;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 2.06;
				letter-spacing: normal;
				color: $marine-blue;
			}
		}
		.filter-row1 {
			position: relative;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: inherit;
			align-items: stretch;
			width: 100% !important;
		}

		.ui.multiple.dropdown {
			width: 200px;
			height: 50px;
			border-radius: 3px;
			box-shadow: 0 2px 20px 0 $black-5;
			border: solid 1px $marine-blue-8;
			background-color: $white;
			text-align: center;
			font-family: Cabin;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.57;
			letter-spacing: normal;
			color: $marine-blue;
			margin-right: 45px;
			.ui.button {
				width: 145px;
				height: 45px;
				font-family: Cabin;
				font-size: 14px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.57;
				letter-spacing: normal;
				color: $marine-blue;
			}
			.dropdown.icon {
				margin: auto;
			}
		}
	}
	.container {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(auto-fit, 324px);
		grid-template-rows: 240px;
		grid-gap: 45px;
	}

	.card {
		display: grid;
		grid-template-columns: [first] 25px [line2] 125px [line3] 113px [line4] 61px [col-end];
		grid-template-rows: [row-start] 40px [row1] 110px [row2] 45px [row3] 45px [row-end];
		grid-template-areas:
			'check image number box'
			'. image title box'
			'result result result box'
			'result1 result1 result1 box';
		border-radius: 7px;
		box-shadow: 0 2px 20px 0 rgba(87, 82, 82, 0.15);
		background-color: $ice-blue-two;
	}
	.item-check {
		grid-column-start: first;
		grid-column-end: 2;
		grid-row-start: row-start;
		grid-row-end: row1;
		.ui.segment {
			height: 40px;
			border: none;
			margin: 0 0 0 -20px;
			padding: 5px;
			box-shadow: none;
		}
		.ui.checkbox {
			vertical-align: middle;
		}
		.ui.checkbox label:before {
			width: 20px;
			height: 20px;
			// top: -3px;
			left: 0px;
		}
		.ui.checkbox label:after {
			font-size: 15px;
			color: #1797d1 !important;
			left: 2px;
		}
	}
	.item-image {
		grid-column-start: 2;
		grid-column-end: 3;
		grid-row-start: row-start;
		grid-row-end: row2;
		margin-top: 8px;
		cursor: pointer;
		img.ui.image {
			display: block;
			border-radius: 15px;
			width: 107px;
			height: 107px;
		}
	}
	.item-number {
		grid-column-start: 3;
		grid-column-end: 4;
		grid-row-start: row-start;
		grid-row-end: row1;
		font-family: FuturaBT;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 2;
		letter-spacing: normal;
		color: $marine-blue;
		margin-top: 7px;
	}
	.item-title {
		grid-column-start: 3;
		grid-column-end: col-end;
		grid-row-start: row1;
		grid-row-end: row2;
		word-break: break-all;
		font-family: Cabin;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.71;
		letter-spacing: normal;
		color: $marine-blue;
		margin-right: 15px;
	}
	.item-result {
		align-items: center;
		display: grid;
		grid-column-start: first;
		grid-column-end: col-end;
		grid-row-start: row2;
		grid-row-end: row-end;
		grid-template-columns: [res-first] 30px [line2] 55px [line3] 60px [line4] 85px [line5] 50px [res-end];
		grid-template-rows: 40px;
		grid-template-areas: 'name name title title';
		border-top: 2px solid $black-6;
		.item-result-name {
			text-align: center;
			grid-column-start: 1;
			grid-column-end: 3;
			font-family: FuturaBT;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			color: $marine-blue;
		}
		.item-result-title {
			margin-left: -7px;
			display: flex;
			align-items: center;
			grid-column-start: 3;
			grid-column-end: res-end;
			font-family: Cabin;
			font-size: 14px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			color: $dark-sky-blue-four;
			.button {
				margin-left: 5px;
				width: 47px;
				height: 29px;
				border-radius: 22.5px;
				background-repeat: no-repeat;
				background-position: center;
			}
			.right {
				background-color: $aqua-green-30;
				background-image: url('../../images/right.svg');
			}
			.wrong {
				background-color: $orange-red-30;
				background-image: url('../../images/wrong.svg');
			}
		}
	}
	.item-result1 {
		align-items: baseline;
		display: grid;
		grid-column-start: first;
		grid-column-end: col-end;
		grid-row-start: row3;
		grid-row-end: row-end;
		grid-template-columns: [res-first] 30px [line2] 55px [line3] 60px [line4] 85px [line5] 50px [res-end];
		grid-template-rows: 40px;
		grid-template-areas: 'name name title title';
		.item-result-name {
			text-align: center;
			grid-column-start: 1;
			grid-column-end: 3;
			font-family: FuturaBT;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			color: $marine-blue;
		}
		.item-result-title {
			margin-left: -7px;
			grid-column-start: 3;
			grid-column-end: res-end;
			font-family: Cabin;
			font-size: 14px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			color: $peacock-blue;
		}
	}
	.item-box {
		display: none;
		grid-column-start: 4;
		grid-column-end: col-end;
		grid-row-start: row-start;
		grid-row-end: row-end;
		border-radius: 7px;
		background-color: #e0f6ff;
		grid-template-columns: 61px;
		grid-template-rows: [bRow-start] 120px [bRow1] 120px [bRow-end];
		cursor: pointer;
		div {
			display: grid;
			align-content: center;
			justify-content: center;
		}
		.box1 {
			grid-row-start: bRow-start;
			grid-row-end: bRow1;
			border-bottom: solid 1px $warm-grey-three;
		}
		.box2 {
			grid-row-start: bRow1;
			grid-row-end: bRow-end;
		}
		// .box3 {
		// 	padding-left: 18px;
		// 	padding-top: 28px;
		// 	grid-row-start: bRow2;
		// 	grid-row-end: bRow-end;
		// 	border-top: solid 1px $warm-grey-three;
		// }
		.box-icon {
			font-size: 18px;
			margin: 20px 0 0 13px;
		}
	}
	.card:hover {
		.item-box {
			display: grid;
		}
	}
	.card-grid {
		display: flex;
		flex-wrap: wrap;
		margin-top: 15px;
		width: 100%;
		margin-top: 15px;
		padding-bottom: 20px;
		align-self: flex-start;
		margin-left: 2.5%;
		.card {
			display: flex;
			width: 324px;
			height: 240px;
			border-radius: 9px;
			box-shadow: 2px 5px 15px 0 #000f5611;
			background-color: $white;
			margin: 0 0 30px 44px;
			.card-row > * {
				margin-top: 8px;
			}
			.card-row {
				padding: 0;
				.index {
					@include header1(10.8px, 500, 1.5, $marine-blue, normal);
				}
				.check {
					margin-top: 0;
					margin-left: -15px;
					width: 32px !important;
					height: 41px;
					background: white;
					padding: 6px;
					border-top-left-radius: 7px;
					border-bottom-left-radius: 7px;
				}
				.card-row-column {
					margin-left: 12px;
					padding: 0;
					width: 107px;
					height: 107px;
					.ui.image {
						width: 100%;
						height: 100%;
						border-radius: 10px;
					}
				}
				.card-row-column2 {
					word-break: break-all;
					height: 48px;
					@include header1(12px, 500, 2, $marine-blue, normal);
				}
			}
			.results > * {
				align-items: center;
				width: 85%;
				margin: auto;
				display: flex;
				.title {
					width: 61px;
					margin: 0;
					padding: 0;
					@include header1(12px, normal, 2.4, $marine-blue, normal, Futura);
				}
				.code {
					display: flex;
					flex-direction: column;
					span {
						background-image: linear-gradient(145deg, $azure 13%, $seafoam-blue 108%);
						font-family: Cabin;
						font-size: 14px;
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.43;
						letter-spacing: normal;
						-webkit-background-clip: text;
						background-clip: text;
						-webkit-text-fill-color: transparent;
					}
				}
			}
			.results {
				margin-top: 28px;
				padding: 0;
				.card-row2 {
					height: 50px;
					border-top: 2px solid $black-6;
					padding-bottom: 0;
					@include header1(12px, 500, 1.6, $marine-blue, normal);
					.column {
						margin-right: 5px;
					}
					.btn-grp {
						justify-self: flex-end;
						margin: 0 0 0 auto;
						.button {
							margin-left: 7px;
							height: 29px;
							width: 47px;
							width: 47px;
							height: 29px;
							border-radius: 15px;
							font-family: Cabin;
							font-size: 8.4px;
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: normal;
							letter-spacing: normal;
							background-color: $white;
							.icon {
								left: 25%;
								background: none;
							}
						}
						.wrong {
							background-color: $orange-red;
							border: 1px solid $orange-red;
							.icon {
								color: $white;
							}
						}
						.right {
							background-color: $aqua-green;
							border: 1px solid $aqua-green;
							.icon {
								color: $white;
							}
						}
						.basic.right {
							.icon {
								color: $aqua-green;
							}
						}
						.basic.wrong {
							.icon {
								color: $orange-red;
							}
						}
					}
				}
				.card-row3 {
					height: 25px;
					font-family: Cabin;
					font-size: 12px;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: normal;
					letter-spacing: normal;
					color: $marine-blue;
					align-items: baseline;
					.drpdwn {
						margin-left: 5px;
						width: 79px;
						height: 30px;

						.ui.dropdown {
							height: 30px;
							width: 79px;
							display: flex;
							padding: 0;
							justify-content: space-evenly;
							align-items: center;
							border-radius: 3px;
							box-shadow: 0 2px 20px 0 $black-5;
							border: solid 1px $marine-blue-8;
							background-color: $white;
							line-height: 2.4;
							margin: 0;
							.text {
								width: 53px;
								margin: 0;
								font-family: Cabin;
								font-size: 10px;
								font-weight: normal;
								font-stretch: normal;
								font-style: normal;
								letter-spacing: normal;
								color: $marine-blue;
							}
							.icon {
								margin: 0;
							}
							.menu {
								margin-left: 0;
								width: 147px;
								height: 300px;
								// overflow-y: scroll;
								border-radius: 3px;
								box-shadow: 0 2px 20px 0 $black-5;
								border: solid 1px $marine-blue-8;
								background-color: $white;
								.heading {
									display: flex;
									font-family: Cabin;
									font-size: 10px;
									font-weight: normal;
									font-stretch: normal;
									font-style: normal;
									line-height: 3;
									letter-spacing: normal;
									color: $marine-blue;
									div:first-child {
										margin-left: 10px;
									}
									.icon-grp {
										width: 60px;
										display: flex;
										justify-content: flex-end;
										img {
											margin-left: 10px;
											position: inherit;
											width: 10px;
										}
									}
								}
								.search {
									width: 125px;
									margin: auto;
								}
								.search > input {
									width: 115px;
									height: 25px;
									border-radius: 2.1px;
									border: solid 0.7px $marine-blue-43;
									background-color: $white;
								}
								.search > input::placeholder {
									opacity: 0.3;
									font-family: Cabin;
									font-size: 9.8px;
									font-weight: normal;
									font-stretch: normal;
									font-style: normal;
									line-height: 1.86;
									letter-spacing: normal;
									color: $marine-blue;
								}
								.options {
									height: 193px;
									display: flex;
									flex-direction: column;
									overflow-y: scroll;
									overflow-x: hidden;
									margin: 10px 0 5px 0;
									.ui.radio.checkbox {
										min-height: 20px;
										display: flex;
										align-items: center;
										width: 130px;
										margin: auto;
										margin-left: 10px;
										[type='radio']:checked,
										[type='radio']:not(:checked) {
											position: absolute;
											left: -9999px;
										}
										[type='radio']:checked + label,
										[type='radio']:not(:checked) + label {
											position: relative;
											padding-left: 16px;
											font-family: Cabin;
											font-size: 10px;
											font-weight: normal;
											font-stretch: normal;
											font-style: normal;
											letter-spacing: normal;
											color: $marine-blue;
											cursor: pointer;
										}
										[type='radio']:checked + label:before,
										[type='radio']:not(:checked) + label:before {
											content: '';
											position: absolute;
											left: 0;
											top: 2.5px;
											width: 11px;
											height: 11px;
											border: 1px solid $azure;
											border-radius: 100%;
											background: #fff;
										}
										[type='radio']:checked + label:after,
										[type='radio']:not(:checked) + label:after {
											content: '';
											width: 6.25px;
											height: 6.25px;
											background-image: linear-gradient(179deg, $azure 33%, $seafoam-blue 235%);
											position: absolute;
											top: 4.6px;
											left: 2.8px;
											border: 1px solid $azure;
											border-radius: 100%;
											// -webkit-transition: all 0.2s ease;
											transition: none;
										}
										[type='radio']:not(:checked) + label:after {
											opacity: 0;
											-webkit-transform: scale(0);
											transform: scale(0);
										}
										[type='radio']:checked + label:after {
											opacity: 1;
											-webkit-transform: scale(1);
											transform: scale(1);
										}
									}
								}

								button {
									margin-left: 15px;
									@include b-secondary(63px, 22px, $white, 12px, normal);
								}
								::-webkit-scrollbar {
									width: 6px;
								}

								/* Track */
								::-webkit-scrollbar-track {
									box-shadow: inset 0 0 10px $white;
									border-radius: 10px;
								}

								/* Handle */
								::-webkit-scrollbar-thumb {
									background: $cloudy-blue;
									border-radius: 10px;
								}

								/* Handle on hover */
								::-webkit-scrollbar-thumb:hover {
									background: $white;
								}
							}
						}
					}
				}
			}
		}
	}
}

.trend {
	margin-left: 49.8px !important;
	margin-top: 30px !important;
	width: 465px;
	height: 182px;
	border-radius: 10px;
	box-shadow: 0 2px 30px 0 $black-6;
	background-color: $white;
	.trend-row {
		.trend-row-column {
			width: 232px !important;
			font-family: Futura;
			font-size: 18px;
			font-weight: 500;
			line-height: 33px;
			color: $marine-blue;
			margin-top: 20px !important;
			margin-left: 40px;
		}
		.trend-row-column2 {
			width: 232px;
		}
	}
	.trend-row2 {
		.trend-row2-column {
			width: 232px !important;
			.button {
				width: 140px;
				height: 44px;
				border-radius: 22px;
				border: solid 1px $grapefruit-two;
				margin-left: 40px;
				margin-bottom: 45px;
				background: transparent;
				color: $grapefruit-two;
			}
			.button:hover {
				width: 140px;
				height: 44px;
				border-radius: 22px;
				background-color: $grapefruit-two;
				color: $white;
			}
			.button:focus {
				background-color: $pale-red;
				color: $white;
			}
		}
		.trend-row2-column2 {
			width: 232px;
		}
	}
}
.aiResult-grid {
	width: 360px;
	height: 555px;
	box-shadow: 0 2px 20px 0 $black-11;
	background-color: $white;
	.aiResultColumn {
		width: 161px;
		height: 445px;
		display: flex;
		border-right: 2px solid rgba(34, 36, 38, 0.15);
		margin-top: 25px;
		.aiResultLabel {
			width: 161px;
			height: 30px;
			margin-left: 30px;
			display: flex;

			.aiResultTitle {
				font-family: Cabin;
				font-size: 14px;
				font-weight: 600;
				line-height: 30px;
				color: $marine-blue;
			}
			.button {
				font-family: Cabin;
				font-size: 12px;
				font-weight: 500;
				color: $grapefruit-two;
				margin-left: 16px;
				background: transparent;
			}
		}
		.searchInput {
			.ui.icon.input > input {
				color: $salmon;
				width: 150px;
			}
		}
		.ui.checkbox {
			margin: 5px 0px 10px 33px;

			input[type='checkbox'] {
				&:checked + label:after {
					background-image: linear-gradient(179deg, $azure 33%, $seafoam-blue 235%);
					border-radius: 2px;
					border-style: solid;
					border-width: 0.5px;
					border-color: $azure;
					color: white; /* Color of the checkmark */
					content: '\2713'; /* This is a "heavy" checkmark. Change to 2713 for a light one. */
					font-size: 14px;
				}
			}
		}
		.check-list {
			display: flex;
			flex-direction: column;
			height: 350px;
			width: 161px;
			overflow-x: auto;
			margin-top: 15px;
		}

		.ui.checkbox input {
			width: 15px;
			height: 15px;
		}
		.ui.checkbox label {
			font-family: Cabin;
			font-size: 14px;
			line-height: 17px;
			color: $marine-blue;
		}
	}
	.aiResultColumn2 {
		width: 191px;
		height: 420px;
		display: flex;
		margin-top: 25px;
		.aiResultLabel {
			width: 191px;
			height: 30px;
			margin-left: 30px;
			display: flex;
			.aiResultTitle {
				font-family: Cabin;
				font-size: 14px;
				font-weight: 600;
				line-height: 30px;
				color: $marine-blue;
			}

			.button {
				font-family: Cabin;
				font-size: 12px;
				font-weight: 500;
				color: $grapefruit-two;
				margin-left: 16px;
				background: transparent;
			}
		}
		.searchInput {
			.ui.icon.input > input {
				color: $salmon;
				width: 150px;
			}
		}
		.ui.checkbox {
			margin: 5px 0px 10px 33px;
		}
		.check-list {
			display: flex;
			flex-direction: column;
			height: 350px;
			width: 162px;
			overflow-x: auto;
			margin-top: 15px;
		}
		.ui.checkbox input {
			width: 15px;
			height: 15px;
		}
		.ui.checkbox label {
			font-family: Cabin;
			font-size: 14px;
			line-height: 17px;
			color: $marine-blue;
		}
	}
	.aiResultButton {
		width: 114px;
		height: 40px;
		.button {
			color: #f56c6c;
			margin: 0 0 24px 30px;
			@include b-secondary(114px, 40px, $white, 12px, normal);
		}
	}
}
.ui.green.button,
.ui.green.buttons .button {
	background-color: $shamrock-80;
}
.extraClass1 {
	margin-top: 0px !important;
	text-align: center;
	@include header1(12px, 500, normal, $marine-blue !important, normal);
}
.ui.button:disabled {
	opacity: 1 !important;
}

.card-tip.__react_component_tooltip.place-left {
	opacity: 1;
	box-shadow: 0 14px 30px 0 #0000002e;
	margin-left: -10px !important;
	padding: 5px 10px !important;
	line-height: 1.5;
	height: 30px;
	font-family: FuturaBT;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	color: $marine-blue;
}
.card-tip-extra.__react_component_tooltip.place-left {
	opacity: 1;
	box-shadow: 0 14px 30px 0 #0000002e;
	margin-left: -10px !important;
	padding: 0;
	line-height: 1.5;
	font-family: FuturaBT;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.67;
	letter-spacing: normal;
	color: $marine-blue;
	height: 102px;
	width: 134px;
	div {
		height: 34px;
	}
}
.card-tip-more.__react_component_tooltip.place-left {
	font-family: FuturaBT;
	cursor: pointer;
	box-shadow: 0 14px 30px 0 #0000002e;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: $marine-blue;
	height: 102px;
	width: 134px;
	opacity: 1;
	// margin-left: -5px !important;
	padding: 0 0 !important;
	div {
		height: 34px;
		padding: 0 10px;
		line-height: 2.3;
		img {
			margin-right: 10px;
			vertical-align: middle;
		}
	}
	div:hover {
		background-color: $azure-5;
	}
}
.b-contain *,
.b-contain *::before,
.b-contain *::after {
	box-sizing: content-box !important;
}

.b-contain input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.b-contain span {
	line-height: 2;
	font-size: 1rem;
	font-family: inherit;
}

.b-contain {
	display: table;
	position: relative;
	padding-left: 2.8rem;
	cursor: pointer;
	margin-bottom: 0.5rem;
	font-family: FuturaBT;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	color: $marine-blue;
}

.b-contain input[type='checkbox'] ~ .b-input {
	position: absolute;
	top: 2px;
	left: 2px;
	width: 22px;
	height: 22px;
	border-radius: 5px;
	background: $white;
	// transition: background 250ms;
	border: 2px solid $azure;
}
.b-contain input[type='checkbox']::after {
	border: none;
}
.b-contain input[type='checkbox'] ~ .b-input::after {
	content: '';
	position: absolute;
	display: none;
	left: 0.68rem;
	top: 0.2rem;
	width: 0.4rem;
	height: 1rem;
	border: 1px solid $white;
	border-width: 0 2px 2px 0;
	transition: background 250ms;
	transform: rotate(45deg);
}

.b-contain input:checked ~ .b-input {
	display: block;
	width: 25px;
	height: 25px;
	border: none;
}
.b-contain input:checked ~ .b-input::after {
	display: block;
}

.b-contain input:checked ~ .b-input {
	background: linear-gradient(149deg, $azure 11%, $seafoam-blue 112%);
}

.b-contain input[type='checkbox']:disabled ~ .b-input {
	background: rgba(241, 245, 248, 1);
	border-color: $azure;
	opacity: 0.6;
	cursor: not-allowed;
}

.b-contain input:checked:focus ~ .b-input,
.b-contain:hover input:not([disabled]):checked ~ .b-input {
	background: linear-gradient(149deg, $azure 11%, $seafoam-blue 112%);
	width: 25px;
	height: 25px;
	border: none;
}
