.DataAnnotation {
	float: right;
	width: 1030px;
	min-height: 630px;
	border-radius: 20px;
	background-image: url('../../images/bg.jpeg');
	font-family: Futura;
	padding-bottom: 40px;
}
.history.ui.grid {
	width: 900px;
}
.history.ui.grid > .row {
	padding: 0px;
}
.history > .row > p {
	opacity: 0.5;
	font-family: Cabin;
	font-size: 15px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.13;
	letter-spacing: normal;
	color: #02435d;
}
