@import '../colors.scss';

.container {
	max-width: 1440px;
	margin: auto;
}
.data-container {
	display: flex;
}
.page {
	float: right;
	width: 78.5%;
	flex-grow: 1;
	min-height: 630px;
	border-radius: 20px;
	background-image: url('../../images/bg.jpeg');
	background-repeat-x: no-repeat;
	background-size: 100%;
	font-family: Futura !important;
	padding-bottom: 40px;
	display: flex;
	align-items: center;
	flex-direction: column;
	position: relative;
}

// .ss-big:hover {
//   box-shadow: 0 10px 20px 0 $salmon-45 !important;
//   background-color: $salmon-two !important;
// }
// .ss-big:focus {
//   box-shadow: 0 10px 20px 0 $salmon-36 !important;
//   background-color: $blush !important;
// }
/* .ui.button.app-button-secondary  */
// .ss-main {
//   color: $salmon !important;
//   border-radius: 20px !important;
//   background-color: $salmon-20-two !important;
// }
// /* .ui.button.app-button-secondary:hover  */
// .ss-main:hover {
//   box-shadow: 0 10px 20px 0 $salmon-20 !important;
//   background-color: $salmon-20-two !important;
// }
// .ss-main:focus {
//   box-shadow: 0 10px 20px 0 $salmon-40 !important;
//   background-color: $salmon-20-two !important;
// }
.clickable {
	cursor: pointer;
}

.ui.dimmer {
	overflow: hidden;
}
.infinite-scroll-component__outerdiv {
	width: 100%;
}
.scrolling.dimmable.dimmed > .dimmer {
	overflow: hidden;
}
.scrolling.dimmable > .dimmer {
	justify-content: unset;
	padding: 0;
}
.modals.dimmer .ui.scrolling.modal {
	margin: 0;
}
.ui.loader {
	display: block;
}
// * {
//   background-color: rgba(255, 0, 0, 0.2);
// }
// * * {
//   background-color: rgba(0, 255, 0, 0.2);
// }
// * * * {
//   background-color: rgba(0, 0, 255, 0.2);
// }
// * * * * {
//   background-color: rgba(255, 0, 255, 0.2);
// }
// * * * * * {
//   background-color: rgba(0, 255, 255, 0.2);
// }
// * * * * * * {
//   background-color: rgba(255, 255, 0, 0.2);
// }
// * * * * * * * {
//   background-color: rgba(255, 0, 0, 0.2);
// }
// * * * * * * * * {
//   background-color: rgba(0, 255, 0, 0.2);
// }
// * * * * * * * * * {
//   background-color: rgba(0, 0, 255, 0.2);
// }
