@import '../colors.scss';
@import '../header.scss';

.performance {
  font-family: Futura !important;
  .filter-label {
    height: 16.5px;
    margin: 16px 0px 7.5px 0px;
    @include header3(16px, normal, 2.06, $marine-blue, normal);
  }

  .filters {
    margin: auto;
    width: 90%;
    .ui.dropdown > .dropdown.icon {
      display: none;
    }
    width: 930px;

    .filter-btn {
      background: $white;
      width: 215px;
      height: 50px;
      border-radius: 1.5px;
      box-shadow: 0 1px 10px 0 $black-5;
      border: solid 0.5px $marine-blue-8;
      background-color: $white;
      text-align: left;
      font-family: Cabin;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: $marine-blue;
    }
    .ui[class*='right labeled'].icon.button > .icon {
      background: $white;
      color: $marine-blue;
    }
  }

  .title {
    margin: 20.5px 0px 0px 35px;
    @include header2(18px, 500, 1.83, $marine-blue, normal);
  }
  .select-filter {
    margin-left: 49.8px;
    margin-top: 41px;
    margin-bottom: 40px;
    width: 930px;
    height: auto;
    .select-filter-title {
      width: 930px;
      height: 36px;
      display: flex;
      .selected-title {
        // width: 223px;
        height: 33px;
        font-family: FuturaBT;
        font-size: 16px;
        line-height: 33px;
        color: $marine-blue;
        display: flex;
        .show-filter-btn {
          width: 148px;
          height: 18px;
          background: transparent;
          @include header1(14px, 500, normal, $grapefruit-two, normal);
        }
      }
    }
    .hr {
      width: 930px;
      height: 1px;
      opacity: 0.5;
      border: solid 1px #979797;
    }
    .selected-filter-results {
      width: 930px;
      display: flex;
      .selected-result {
        width: 238px;
        margin-left: 23px;
        margin-left: 14px;
        margin-top: 14px;
        .selected-filter-title {
          width: 238px;
          height: 33px;
          margin-bottom: 5px;
          @include header1(16px, 500, 33px, $marine-blue, normal);
        }
        .selected-filter-results {
          width: 238px;
          height: auto;
          display: flex;
          flex-wrap: wrap;
          .label {
            display: flex;
            margin: 0 5px 5px 0px;
            padding: 0 12px;
            height: 35px;
            border-radius: 17.5px;
            background: $azure-1;
            font-family: Cabin;
            font-size: 12px;
            line-height: 2.9em;
            color: $marine-blue;

            .close {
              cursor: pointer;
              float: right;
              padding-left: 8px;
            }
          }
        }
      }
    }
    .vr {
      width: 1px;
      height: auto;
      opacity: 0.3;
      border: solid 1px #979797;
    }
  }
  .ui.grid > .row > .column {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0px;
  }
}

.ui.modal.package-modal {
  width: 50%;
  height: 50%;
  top: 50px;
  margin: 10% 25%;
  margin: 0;
  background-color: $white !important;
  border-radius: 15px;
  box-shadow: 0 2px 40px 0 $black-8;
  .content {
    margin: 0 0 0 60px;
    font-family: Cabin;
    color: $marine-blue;
    width: 90%;
    height: auto;
    .ui.grid {
      .title {
        font-size: 18px;
      }
      .close {
        flex-direction: row-reverse;
      }
      .ui.form {
        width: 100%;
        .field {
          .input {
            .icon {
              color: $marine-blue;
              opacity: 1;
            }
          }
        }
        .field > label,
        .ui.radio.checkbox label {
          font-size: 14px;
          color: $marine-blue;
          font-weight: normal;
        }
        .fields {
          .reset {
            font-family: Cabin;
            font-size: 14px;
            font-weight: 500;
            text-align: right;
            color: $grapefruit-two;
            margin-left: 68%;
          }
        }
      }
      .ui.button {
        width: 114px;
        height: 40px;
        border-radius: 22px;
        border: solid 1px $grapefruit;
        background: transparent;
        font-family: Cabin;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $grapefruit;
      }
    }
  }
}
