@import '../colors.scss';

.DayPicker {
  width: 100%;
  height: 100%;
  .DayPicker-Month {
    width: 100%;
    height: 100%;
    .DayPicker-Caption {
      .year,
      .month {
        margin-bottom: 20px;
        padding-left: 10px;
        width: 148px;
        height: 41px;
        border-radius: 3px;
        border: solid 1px $marine-blue-29;
        background-color: $white;
        font-family: Cabin;
        font-size: 14px;
        line-height: 30px;
        color: $marine-blue;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 96%;
        background-position-y: 5px;
      }
      .month {
        margin-left: 9px;
      }
    }
  }
}

.Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: $white-three !important;
  color: $grapefruit-two;
}
.Range .DayPicker-Day {
  border-radius: 5px !important;
}
.DayPicker-Day,
.DayPicker-Weekday {
  font-family: Cabin;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: $greyish-brown;
  width: 27px;
  height: 27px;
}

.DayPicker-Day--outside {
  opacity: 0.5;
  font-family: Cabin;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: $greyish-brown;
}
.DayPicker-Day--today {
  color: #d0021b;
  font-weight: 700;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: $grapefruit-two;
  border: none;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: $grapefruit-two;
  border-radius: 5px;
  border: none;
}
.DayPicker-NavBar {
  display: none;
}

.link.ui.basic.button {
  margin-left: 25px;
  width: 111px;
  height: 40px;
  border-radius: 22px;
  font-weight: 500;
  text-align: center;
}

.link.disable.ui.basic.button {
  color: $greyish !important;
  border: solid 1px $greyish;
}

.link.enable.ui.basic.button {
  color: $grapefruit-two !important;
  border: solid 1px $grapefruit-two;
}
