.via-cover {
  position: relative;
  .via-image {
    width: 100%;
    height: 100%;
  }
  canvas {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}
