@import '../colors.scss';
@import '../header.scss';

.display-visible {
	max-height: 500px;
	overflow: hidden;
	transition: max-height 0.5s ease;
}
.panel-close {
	max-height: 0;
}
.display-collapsed {
	max-height: 0px !important;
	overflow: hidden;
	transition: max-height 0.3 ease-in;
}

@mixin bar {
	float: left;
	width: 8px;
	height: 37px;
	border-radius: 0px 5px 5px 0px;
}
.menu {
	position: relative;
	float: left;
	height: 100%;
	overflow-x: hidden;
	transition: width 0.5s;
	padding-right: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	ul {
		height: 100%;
		padding: 0;
		transform: scaleY(1);
		cursor: pointer;
		position: fixed;
		width: inherit;
		li {
			list-style: none;
			display: flex;
		}
	}
	.upload-item:hover {
		.upload {
			background: url('../../images/upload_hover.svg');
		}
	}
	.notepad-item:hover {
		.notepad {
			background: url('../../images/notepad_hover.svg');
		}
	}
	.speed-item:hover {
		.speed {
			background: url('../../images/speed_hover.svg');
		}
	}
	.feedback-item:hover {
		.feedback {
			background: url('../../images/feedback_hover.svg');
		}
	}
	.model-item:hover {
		.model {
			background: url('../../images/models_hover.svg');
		}
	}
	.item:hover,
	.item-sub:hover {
		border-radius: 8px;
		background-color: $ice-blue-three;
		.bar {
			background-color: $ice-blue-three;
		}
	}
	.item {
		height: 50px;
		align-items: center;
		a,
		div {
			height: 37px;
			flex-direction: row;
			display: flex;
			.icon {
				display: none;
				line-height: 1.8;
				font-size: 1.2em;
			}
			.bar {
				position: absolute;
				background: $white;
				@include bar();
			}
			.title,
			.title-sub {
				align-items: center;
				white-space: nowrap;
				opacity: 0.5;
				@include header2(14px, normal, $marine-blue, normal);
			}
			div {
				width: 75px;
				background-repeat: no-repeat !important;
				background-position: center !important;
			}
			.upload {
				background: url('../../images/upload.svg');
			}
			.notepad {
				background: url('../../images/notepad.svg');
			}
			.speed {
				background: url('../../images/speedometer.svg');
			}
			.feedback {
				background: url('../../images/feedback_menu.svg');
			}
			.model {
				background: url('../../images/models.svg');
			}
		}
		.active {
			.upload {
				background: url('../../images/upload_active.svg');
			}
			.notepad {
				background: url('../../images/notepad_active.svg');
			}
			.speed {
				background: url('../../images/speed_active.svg');
			}
			.feedback {
				background: url('../../images/feedback_active.svg');
			}
			.model {
				background: url('../../images/models_hover.svg');
			}
		}
		.image {
			margin: auto;
			margin-left: 28px;
			margin-right: 28px;
			height: 20px;
			width: 20px;
		}
		.active {
			@include header2(14px, normal, $marine-blue, normal);
			flex-direction: row;
			display: flex;
			opacity: 1;
			.bar {
				box-shadow: 5px 0 10px 0 $dark-sky-blue-50;
				background-image: linear-gradient(169deg, $azure, $seafoam-blue);
			}
			.title,
			.title-sub {
				white-space: nowrap;
				opacity: 1;
				font-weight: 500;
			}
			.icon {
				display: inline;
				color: $marine-blue;
			}
		}
	}

	.item-sub {
		margin-left: 75px;
		a {
			.title-sub {
				height: 35px;
				white-space: nowrap;
				@include header2(14px, normal, $marine-blue, normal);
				line-height: 2.3;
				opacity: 0.5;
			}
		}
		.active {
			@include header2(14px, normal, $marine-blue, normal);
			flex-direction: row;
			display: flex;

			.title-sub {
				height: 35px;
				line-height: 2.3;
				white-space: nowrap;
				opacity: 1;
				font-weight: 500;
			}
			.icon {
				display: inline;
				color: $marine-blue;
			}
		}
	}
	img {
		cursor: pointer;
		pointer-events: all;
		position: fixed;
		z-index: 100;
		bottom: 11vh;
		transition: left 0.5s;
	}
}
// .expand {
//   width: 0;
//   height: 0;
//   // background: red url('../../images/expand.png') no-repeat fixed;
//   bottom: 10vh;
//   border-top: 15px solid transparent;
//   border-bottom: 15px solid transparent;
//   border-left: 15px solid white;
//   position: fixed;
//   display: inline-block;
//   z-index: 10;
// }

.expand:after {
	content: '';
	width: 0;
	height: 0;
	// background: red url('../../images/expand.png') no-repeat fixed;
	bottom: 10vh;
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
	border-left: 15px solid $white;
	position: fixed;
	display: inline-block;
	z-index: 10;
}
