@mixin header1($fontSize, $fontWeight, $lineHeight, $fontColor, $letterSpace, $fontFamily: Cabin) {
  font-family: $fontFamily !important;
  font-size: $fontSize;
  font-weight: $fontWeight;
  font-style: normal;
  font-stretch: normal;
  line-height: $lineHeight;
  letter-spacing: $letterSpace;
  color: $fontColor;
}
@mixin header2($fontSize, $fontWeight, $fontColor, $letterSpace, $lineHeight: normal) {
  font-family: Futura !important;
  font-size: $fontSize;
  font-weight: $fontWeight;
  font-style: normal;
  font-stretch: normal;
  line-height: $lineHeight;
  letter-spacing: $letterSpace;
  color: $fontColor;
}
@mixin header3($fontSize, $fontWeight, $lineHeight, $fontColor, $letterSpace) {
  font-family: FuturaBT !important;
  font-size: $fontSize;
  font-weight: $fontWeight;
  font-style: normal;
  font-stretch: normal;
  line-height: $lineHeight;
  letter-spacing: $letterSpace;
  color: $fontColor;
}
//header1 without color
@mixin header11($fontSize, $fontWeight, $lineHeight, $letterSpace) {
  font-family: Cabin !important;
  font-size: $fontSize;
  font-weight: $fontWeight;
  font-style: normal;
  font-stretch: normal;
  line-height: $lineHeight;
  letter-spacing: $letterSpace;
}
//header2 without color
@mixin header21($fontSize, $fontWeight, $lineHeight, $letterSpace) {
  font-family: Futura !important;
  font-size: $fontSize;
  font-weight: $fontWeight;
  font-style: normal;
  font-stretch: normal;
  line-height: $lineHeight;
  letter-spacing: $letterSpace;
}
