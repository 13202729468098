$pale-grey: #f1fbff;
$azure: #1ca7e5;
$seafoam-blue-0: rgba(101, 215, 200, 0);
$salmon: #ff7d7d;
$seafoam-blue: #65d7c8;
$marine: #05455f;
$white: #ffffff;
$very-light-pink: #d8d8d8;
$dark-navy-blue: #001117;
$pale-olive: #b3b5af;
$grapefruit: #ff5656;
$very-light-pink-two: #c9c9c9;
$white-two: #f9f9f9;
$marine-blue-13: rgba(2, 67, 93, 0.13);
$black: #000000;
$black-50: rgba(0, 0, 0, 0.5);
$black-40: rgba(0, 0, 0, 0.4);
$black-two-50: rgba(11, 10, 10, 0.5);
$dark-sky-blue: #2fb3dd;
$marine-blue: #02435d;
$bluegrey: #80a1ae;
$battleship-grey: #707175;
$very-light-pink-three: #ffdddd;
$blue-grey: #608492;
$greeny-blue-60: rgba(57, 187, 156, 0.6);
$greeny-blue-10: rgba(57, 187, 156, 0.1);
$azure-10: rgba(28, 167, 229, 0.1);
$azure-two: #bee6f8;
$tealish: #23cdbd;
$salmon-two: #ff7d7c;
$blush: #f27777;
$salmon-20: rgba(255, 124, 122, 0.2);
$salmon-40: rgba(255, 125, 125, 0.4);
$salmon-20-two: rgba(255, 125, 125, 0.2);
$salmon-36: rgba(255, 125, 125, 0.36);
$salmon-45: rgba(255, 125, 125, 0.45);
$azure-10: rgba(28, 167, 229, 0.1);
$steel-grey-20: rgba(119, 135, 142, 0.2);
$dark-sky-blue-50: rgba(40, 174, 224, 0.5);
$azure-5: rgba(28, 167, 229, 0.05);
$azure-2: rgba(28, 167, 229, 0.2);
$progress: #ecf8f6;
$progress-active: rgba(57, 187, 156, 0.54);
$progress-two: #dbf2fc;
$modal-bg: rgba(249, 250, 251, 0.95);
$charcoal-grey: #444f54;
$primary: lightblue;
$marine-blue-8: rgba(2, 67, 93, 0.08);
$black-5: rgba(0, 0, 0, 0.05);
$black-6: rgba(0, 0, 0, 0.06);
$dark-sky-blue-two: #2ab0e0;
$dark-sky-blue-7: rgba(46, 179, 222, 0.07);
$azure-14: rgba(28, 167, 229, 0.14);
$grapefruit-two: rgb(245, 108, 108);
$white-three: #f0f8ff;
$black-11: rgba(0, 0, 0, 0.11);
$marine-blue-29: rgba(2, 67, 93, 0.29);
$greyish-brown: #4a4a4a;
$greyish: #b7b7b7;
$dark-sky-blue-three: #33b6dc;
$warm-grey: #969696;
$marine-blue-10: #e8f6fc;
$black-8: rgba(0, 0, 0, 0.08);
$pale-red: #e24d4d;
$dark-sky-blue-three-20: rgba(51, 182, 220, 0.2);
$grapefruit-two-30: rgba(245, 108, 108, 0.3);
$white-four: #f3f3f3;
$azure-1: rgba(28, 167, 229, 0.1);
$dark-sky-blue-20: rgba(51, 182, 220, 0.2);
$dark-sky-blue-30: rgba(51, 182, 220, 0.3);
$warm-grey-two: #707070;
$warm-grey-three: rgba(150, 150, 150, 0.5);
$grapefruit-three: #ff5858;
$dark-sky-blue-four: #2ab0df;
$shamrock-80: rgba(2, 185, 72, 0.8);
$bright-orange: #ff6600;
$ice-blue: #e3f7ff;
$ice-blue-two: #fafeff;
$dark-sky-blue-18: rgba(39, 174, 224, 0.5);
$dark-sky-blue-66: rgba(37, 174, 226, 0.66);
$dark-sky-blue-6: rgba(46, 179, 222, 0.06);
$ice-blue-three: #e8faff;
$peacock-blue: #026587;
$aqua-green: #11cb9a;
$aqua-green-30: rgba(17, 203, 154, 0.3);
$orange-red: #ff2e2e;
$orange-red-30: rgba(255, 46, 46, 0.3);
$marine-blue-43: rgba(2, 67, 93, 0.43);
$cloudy-blue: #bfd0d6;
